import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../styles/Job.scss';

function ModalValidate({
  show,
  handleClose,
  title,
  message,
  onConfirm,
  closeTitle,
  confirmTitle,
  onClose,
  secondaryBtn,
  className,
}) {
  return (
    <Modal show={show} onHide={onClose} className="hide-close-button">
      <Modal.Header className="m-auto pt-4 pb-1" closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center pt-0 pb-2">{message}</Modal.Body>
      <Modal.Footer className="validate_modal justify-content-center pb-4">
        <Button
          className={
            secondaryBtn
              ? 'py-2 px-4 rounded w-auto btn-secondary'
              : 'py-2 px-4 border_primary border-1 rounded w-auto'
          }
          onClick={handleClose}
        >
          {closeTitle}
        </Button>
        <Button
          className={
            className
              ? `py-2 px-4 border-0 rounded submit text-white confirmBtn`
              : 'py-2 px-4 border-0 rounded submit text-white w-auto'
          }
          onClick={onConfirm}
        >
          {confirmTitle}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalValidate.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  closeTitle: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  secondaryBtn: PropTypes.bool,
  className: PropTypes.bool,
};

ModalValidate.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
};

export default ModalValidate;
