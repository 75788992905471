import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SummaryReport from './SummaryReport';
import BreakdownByJob from './BreakdownByJob';
import Refresh from '../assets/images/refresh.svg';
import CalendarIcon from '../assets/images/calendar.svg';
import LoadingSpinner from './LoadingSpinner';
import axios from 'axios';
import '../styles/shiftReport.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { baseURL } from '../utils/constants';
import ShiftReportHeader from './ShiftReportHeader';
import useShiftedDays from '../utils/useShiftedDays';

function ShiftReport() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedReport, setSelectedReport] = useState('Summary Report');
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const { startDay } = useShiftedDays();

  const filterDate = (date) => {
    if (!startDay) {
      return true; // Allow all dates if startDay is not yet available
    }
    const dayIndex = parseInt(startDay.day, 10);
    return date.getDay() === dayIndex;
  };

  useEffect(() => {
    if (startDate) {
      const selectedFriday = startDate;
      const nextThursday = new Date(selectedFriday.getTime());
      nextThursday.setDate(nextThursday.getDate() + 6); // Add 6 days to get the next Thursday

      setEndDate(nextThursday);
      fetchData(selectedFriday, nextThursday);
    } else {
      setEndDate(null);
      setReportData(null); // Clear report data when no date is selected
    }
  }, [startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate, endDate);
    }
  }, [selectedReport]);

  useEffect(() => {
    const getDefaultDates = () => {
      const today = new Date();
      const dayOfWeek = today.getDay();

      // Calculate last week's Friday
      const lastFriday = new Date(today);
      lastFriday.setDate(today.getDate() - ((dayOfWeek + 2) % 7));

      // Calculate this week's Thursday
      const thisThursday = new Date(today);
      thisThursday.setDate(today.getDate() + ((4 - dayOfWeek + 7) % 7));

      // If today is Friday or later in the week, we need to adjust the calculation
      if (dayOfWeek >= 5) {
        // Last Friday is today or the most recent Friday
        lastFriday.setDate(today.getDate() - (dayOfWeek - 5));

        // This Thursday is the upcoming Thursday
        thisThursday.setDate(today.getDate() + ((4 - dayOfWeek + 7) % 7));
      }

      return { lastFriday, thisThursday };
    };

    const { lastFriday, thisThursday } = getDefaultDates();
    setStartDate(lastFriday);
    setEndDate(thisThursday);
  }, []);

  const handleStartDateChange = (date) => {
    // Ensure only Fridays are selected
    const startDay = 5; // Friday
    if (date && date.getDay() === startDay) {
      setStartDate(date);
      setIsDateSelected(true);
    }
  };

  const handleReportChange = (event) => {
    setSelectedReport(event.target.value);
  };

  const handleWeekChange = (delta) => {
    if (startDate) {
      const newStartDate = new Date(startDate.getTime());
      newStartDate.setDate(newStartDate.getDate() + delta * 7);
      setStartDate(newStartDate);
    }
  };

  const fetchData = async (startDate, endDate) => {
    try {
      setLoading(true);
      const formattedStartDate = startDate.toISOString().slice(0, 10);
      const formattedEndDate = endDate.toISOString().slice(0, 10);
      const endpoint =
        selectedReport === 'Summary Report'
          ? `${baseURL}/crew-timer/shift-report-byUser`
          : `${baseURL}/crew-timer/shift-report-byJob`;
      const response = await axios.post(
        endpoint,
        {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
        { withCredentials: true }
      );
      setReportData(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch report data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshClick = () => {
    // Check if a start date is already selected
    if (startDate) {
      // Re-fetch data using the existing start and end date
      fetchData(startDate, endDate);
    } else {
      console.warn('Cannot refresh without a selected date');
    }
  };

  const renderReport = () => {
    if (loading) {
      return (
        <div className="loader colorLoader">
          {' '}
          <LoadingSpinner />
        </div>
      );
    }

    if (error) {
      return <div>{error}</div>;
    }

    switch (selectedReport) {
      case 'Summary Report':
        return reportData && reportData.length > 0 ? (
          <SummaryReport crewData={reportData} />
        ) : (
          <ShiftReportHeader title="Crew Name" isEmpty />
        );
      case 'Breakdown by job':
        return reportData ? <BreakdownByJob reportData={reportData} /> : null;
      default:
        return null;
    }
  };
  const defaultHeader = selectedReport === 'Summary Report';

  return (
    <div>
      <div className="row flex-column flex-md-row align-items-md-center justify-content-between">
        <div className="col-xl-3 col-md-4 dropdown">
          <select
            value={selectedReport}
            onChange={handleReportChange}
            className="border-0 rounded py-2 fw-bold px-0"
            aria-label="Select report type"
          >
            <option value="Summary Report">Summary Report</option>
            <option value="Breakdown by job">Breakdown by Job</option>
          </select>
        </div>
        <div className="col-xl-9">
          <div className="row justify-content-end me-2">
            <div className="col-xl-7 col-md-8">
              <div className="d-flex flex-row justify-content-md-end border_date rounded position-relative">
                <div className="d-flex align-items-center justify-content-center gap-1">
                  <button
                    className="btn py-1 w_fit ps-3 pe-0 border-0"
                    onClick={() => handleWeekChange(-1)}
                    aria-label="Previous week"
                  >
                    <FontAwesomeIcon
                      className="font-size-14"
                      icon={faChevronLeft}
                    />
                  </button>
                  <button
                    className="btn py-1 w_fit  ps-2 pe-3 border-0"
                    onClick={() => handleWeekChange(1)}
                    aria-label="Next week"
                  >
                    <FontAwesomeIcon
                      className="font-size-14"
                      icon={faChevronRight}
                    />
                  </button>
                </div>
                <div className="position-relative datePicker d-flex align-items-center">
                  <DatePicker
                    onChange={handleStartDateChange}
                    filterDate={filterDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText={isDateSelected}
                    isClearable={true}
                    className="ps-2 py-2 place_holder"
                    aria-label="Select start date"
                  />
                  <span className="font-size-14 position-absolute">
                    {startDate && endDate
                      ? `${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`
                      : ''}
                  </span>
                  <img
                    src={CalendarIcon}
                    alt="Calendar Icon"
                    className="calendar-icon"
                    onClick={() =>
                      document
                        .querySelector('.react-datepicker-wrapper input')
                        .click()
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="border border-1 rounded w_fit">
                <div
                  className="d-flex align-items-center px-3 padding_refresh cursor_pointer"
                  onClick={() => handleRefreshClick()}
                >
                  <img src={Refresh} alt="Refresh" />
                  <h2 className="font-size-14 mb-0 ms-2">Refresh</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {defaultHeader}
      <div className="mt-4">{renderReport()}</div>
    </div>
  );
}

export default ShiftReport;
