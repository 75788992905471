import PropTypes from 'prop-types';
import '../styles/Search.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// import { useState } from 'react';

export function SearchBar({
  placeholder,
  className,
  onSearch,
  onBlur,
  searchInput,
  setSearchInput,
}) {
  // const [searchInput, setSearchInput] = useState('');
  const handleChange = (event) => {
    setSearchInput(event.target.value);
    // Call the onSearch function passed as prop with the updated search input
    onSearch(event.target.value);
  };
  return (
    <div className="inputs position-relative">
      <input
        type="text"
        className={className}
        placeholder={placeholder || 'Search...'}
        onChange={handleChange}
        value={searchInput}
        onBlur={onBlur}
      />
      <FontAwesomeIcon
        className="search-icon"
        icon={faMagnifyingGlass}
        style={{ color: 'black', opacity: 0.5 }}
      />
    </div>
  );
}

SearchBar.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  searchInput: PropTypes.string,
  setSearchInput: PropTypes.func.isRequired,
};

export default SearchBar;
