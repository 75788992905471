import React, { useState } from 'react';
import './login.css';
import signImage from '../../assets/images/mds-logo.svg';
import Button from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import { faUser } from '@fortawesome/free-regular-svg-icons';
import mail from '../../assets/images/mail.svg';
import passwordIcon from '../../assets/images/password.svg';
import LoadingSpinner from '../../components/LoadingSpinner';
import { userLogin } from '../../components/api';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function LoginPage({ onLogin }) {
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};

    if (!email && !password) {
      errors.emailAndPassword = 'Please enter an email address and password';
    } else {
      if (!email) {
        errors.email = 'Please enter an email address';
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        errors.email = 'Please enter a valid email address';
      }

      if (!password) {
        errors.password = 'Please enter a password';
      }
    }

    return errors;
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      const loginPayload = {
        email: email,
        password: password,
      };

      try {
        await userLogin(loginPayload);
        navigate('/admin/crew-management');
        onLogin();
      } catch (err) {
        setErrors({ submit: 'Please enter a valid email address or password' });
      } finally {
        setLoading(false);
      }
    }
  };
  const togglePasswordVisibility = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className="container-fluid bg-white">
      <div className="row flex-xl-row flex-column-reverse align-items-center pt-5 pt-xl-0">
        <div className="col-xl-5 col-12 px-0 mt-5 mt-xl-0">
          <div className="bg_login">
            <div className="d-flex flex-column">
              <img
                src={signImage}
                className="width-rev-px-250 height-rev-px-150 m-auto"
                alt="Signin Image"
              />
              <h1 className="fw-bold text-white font-size-22">
                Field Service Management App
              </h1>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-md-9 col-12 d-flex flex-column justify-content-center align-items-center mt-5 mt-xl-0 mb-5 mb-xl-0 mt-md-5">
          <h2 className="font_family_nunito fw-bold mb-4">Sign In</h2>
          <form
            onSubmit={handleSubmit}
            className="d-flex flex-column width-rev-100 width-rev-xl-50 width-rev-md-100 form-max-width position-relative"
          >
            <div className="credential">
              <div className="d-flex w-100">
                <input
                  type="text"
                  id="email"
                  className="mb-4 flex-grow-1 loginInput"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setUsername(e.target.value)}
                  autoFocus
                />
                <img src={mail} className="credential-icon" alt="mail" />
              </div>
            </div>
            <div className="password-container">
              <div className="d-flex w-100">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Password"
                  className="mb-4 flex-grow-1 loginInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  src={passwordIcon}
                  className="credential-icon"
                  alt="password"
                />
                <div
                  className="ms-0 border-0 p-0 password-icon"
                  onClick={togglePasswordVisibility}
                >
                  {!showPassword ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      style={{ color: 'lightgray' }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ color: 'lightgray' }}
                    />
                  )}
                </div>
              </div>
            </div>
            <Button className="login_btn py-3" type="submit">
              <div className="login_loader">
                {loading ? <LoadingSpinner /> : 'Sign In'}
              </div>
            </Button>
            <div className="w-100 errors mt-1 position-absolute">
              {errors.submit && (
                <p className="text_danger font-size-16 m-0 p-0">
                  {errors.submit}
                </p>
              )}
              {errors.email && (
                <p className="text_danger font-size-14 m-0 p-0">
                  {errors.email}
                </p>
              )}
              {errors.password && (
                <p className="text_danger font-size-14 m-0 p-0">
                  {errors.password}
                </p>
              )}
              {errors.emailAndPassword && (
                <p className="text_danger font-size-14 m-0 p-0">
                  {errors.emailAndPassword}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

LoginPage.propTypes = {
  onLogin: PropTypes.func,
};

export default LoginPage;
