import React, { useState, useEffect, useRef } from 'react';
import '../styles/Job.scss';
import ListHeader from './ListHeader';
import SearchBar from './Search';
import BoxTitle from './BoxTitle';
import Button from './Button';
import PropTypes from 'prop-types';
import { addJob, fetchUserByRoles } from './api';
import ModalValidate from './ModalValidate';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import '../styles/MultiSelectDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

function AddJob() {
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [crewMember, setCrewMember] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState(new Set());
  const [jobName, setJobName] = useState('');
  const [status, setStatus] = useState('');
  const [jobId, setJobId] = useState('');
  const [crewData, setCrewData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedTeamLeaders, setSelectedTeamLeaders] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleOptionClick = (leader) => {
    const leaderId = leader._id;

    if (selectedTeamLeaders.includes(leaderId)) {
      setSelectedTeamLeaders(
        selectedTeamLeaders.filter((id) => id !== leaderId)
      );
    } else {
      if (selectedTeamLeaders.length < 4) {
        setSelectedTeamLeaders([...selectedTeamLeaders, leaderId]);
      } else {
        alert('You can select a maximum of 4 team leaders.');
      }
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchTeamLeaders = async () => {
    try {
      setLoading(true);
      const response = await fetchUserByRoles();
      setTeamLeaders(response.teamLeaders);
      setCrewMember(response.crewMembers);
      setCrewData(response.crewMembers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching crew data:', error);
    }
  };

  useEffect(() => {
    fetchTeamLeaders();
  }, []);

  const handleSearch = (searchInput) => {
    const lowerCaseSearchInput = searchInput.toLowerCase();

    const filtered = crewData.filter((crewMember) => {
      const fullName =
        `${crewMember.firstName} ${crewMember.lastName}`.toLowerCase();
      return (
        crewMember.firstName.toLowerCase().includes(lowerCaseSearchInput) ||
        crewMember.lastName.toLowerCase().includes(lowerCaseSearchInput) ||
        fullName.includes(lowerCaseSearchInput)
      );
    });

    setCrewMember(filtered);
  };

  const formatCrewMembers = () => {
    const formattedCrewMembers = [];
    selectedCrew.forEach((crewId) => {
      const crew = crewMember.find((member) => member._id === crewId);
      if (crew) {
        formattedCrewMembers.push({
          crewId: crew._id,
          name: `${crew.firstName} ${crew.lastName}`,
          _id: crew._id,
        });
      }
    });
    return formattedCrewMembers;
  };

  const formatTeamLeadIds = () => {
    return selectedTeamLeaders;
  };

  const handleSave = async () => {
    setLoading(true);
    const jobData = {
      name: jobName,
      teamLeadIds: formatTeamLeadIds(),
      status,
      crewMembers: formatCrewMembers(),
    };

    const response = await addJob(jobData);
    setJobId(response._id);
    setModalShow(true);
    setLoading(false);
  };

  const handleModalConfirm = () => {
    setModalShow(false);
    navigate(`/admin/job-management/edit-job/${jobId}`);
  };

  const handleSelectAll = () => {
    if (selectedCrew.size === crewMember.length) {
      // If all are currently selected, deselect all
      setSelectedCrew(new Set());
    } else {
      // Otherwise, select all
      const newSelectedCrew = new Set(crewMember.map((member) => member._id));
      setSelectedCrew(newSelectedCrew);
    }
  };

  return (
    <>
      {loading ? (
        <div className="jobLoader colorLoader">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-xl-7 pe-xl-4">
            <div className="row gy-3">
              <div className="col-xl-6">
                <div className="form-group check_box">
                  <label className="fw-bold mb-1" htmlFor="jobName">
                    Enter Job Name
                  </label>
                  <input
                    type="text"
                    className="form-control py-2 w-100"
                    id="jobName"
                    name="jobName"
                    placeholder="Enter a job name"
                    value={jobName}
                    onChange={(e) => setJobName(e.target.value)}
                    required
                    maxLength={30}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <h6 className="fw-bold">Select Team Leaders</h6>
                <div className="multi-select-dropdown" ref={dropdownRef}>
                  <div
                    className="dropdown-header"
                    onClick={() => setShowOptions(!showOptions)}
                  >
                    <div className="dropdown-content">
                      {selectedTeamLeaders.length === 0
                        ? 'Select options'
                        : selectedTeamLeaders
                            .map((id) => {
                              const teamLead = teamLeaders.find(
                                (leader) => leader._id === id
                              );
                              return teamLead
                                ? `${teamLead.firstName} ${teamLead.lastName}`
                                : id;
                            })
                            .join(', ')}
                    </div>
                    <span className="dropdown-arrow">
                      {showOptions ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </span>
                  </div>
                  <p className={`font-size-13 p-0 m-0 text-danger`}>
                    You can select upto 4 leaders
                  </p>
                  {showOptions && (
                    <div className="dropdown-options" id="scrollstyle">
                      {teamLeaders.map((leader) => (
                        <div
                          key={leader._id}
                          className={`d-flex align-items-center justify-content-between dropdown-option ${
                            selectedTeamLeaders.includes(`${leader._id}`)
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => handleOptionClick(leader)}
                        >
                          {leader.firstName} {leader.lastName}
                          {selectedTeamLeaders.includes(`${leader._id}`) && (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="font-size-13"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="edit_job mt-3">
              <h6 className="fw-bold">Select Status</h6>
              <div className="d-flex">
                <div className="form-group fw-bold d-flex align-items-center pe-4">
                  <input
                    type="radio"
                    id="active"
                    name="status"
                    className="w-auto me-2"
                    value="active"
                    checked={status === 'active'}
                    onChange={() => setStatus('active')}
                  />
                  <label className="fw-normal" htmlFor="active">
                    Active
                  </label>
                </div>
                <div>
                  <div className="form-group fw-bold d-flex align-items-center pe-4">
                    <input
                      type="radio"
                      id="complete"
                      name="status"
                      className="w-auto me-2"
                      value="complete"
                      checked={status === 'complete'}
                      onChange={() => setStatus('complete')}
                    />
                    <label className="fw-normal" htmlFor="complete">
                      Complete
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-1 bg-white p-xl-4 p-md-3 p-4 mt-3 rounded ">
              <div className="row flex-column flex-md-row align-items-md-center justify-content-between">
                <div className="col-xl-5 col-md-4">
                  <BoxTitle
                    className="font-size-16 fw-bold mb-0 ms-4 ps-2"
                    title="All Crew"
                  />
                </div>
                <div className="col-xl-6 col-md-8">
                  <div className="d-flex flex-row gap-4 justify-content-md-end search_icon">
                    <SearchBar
                      className="py-2 h-100 font-size-15"
                      placeholder="Search by crew name"
                      onSearch={handleSearch}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                    />
                  </div>
                </div>
              </div>
              <ul className="pe-3 bg_crew_list mt-4 mt-md-4 mb-2 py-3 rounded">
                <li className="row col-12 crew_icon">
                  <div className="col-6">
                    <ListHeader title="Crew Name" noSorting />
                  </div>
                  <div className="col-6 text-end">
                    <h2
                      className="font-size-16 fw-bold mb-0 text-decoration-underline"
                      onClick={handleSelectAll}
                      style={{ cursor: 'pointer' }}
                    >
                      Select All
                    </h2>
                  </div>
                </li>
              </ul>
              <ul className="pe-3 jobList scroll_bar" id="scrollstyle">
                {crewMember.map((crewMember) => (
                  <div key={crewMember.id}>
                    <li className="row col-12">
                      <div className="col-6">
                        <h2 className="font-size-16 crew_name_color mt-2">
                          {crewMember.firstName} {crewMember.lastName}
                        </h2>
                      </div>
                      <div className="col-6 text-end d-flex justify-content-end align-items-center flex-row check_box">
                        <input
                          type="checkbox"
                          id={`crew-${crewMember._id}`}
                          value={crewMember._id}
                          checked={selectedCrew.has(crewMember._id)}
                          onChange={(event) => {
                            const crewId = event.target.value;
                            setSelectedCrew((prevSelectedCrew) => {
                              const newSelectedCrew = new Set(prevSelectedCrew);
                              if (newSelectedCrew.has(crewId)) {
                                newSelectedCrew.delete(crewId);
                              } else {
                                newSelectedCrew.add(crewId);
                              }
                              return newSelectedCrew;
                            });
                          }}
                        />
                      </div>
                    </li>
                    <hr className="hr_line my-2 width-rev-108 width-rev-md-108 width-rev-xl-109" />
                  </div>
                ))}
              </ul>
            </div>
            <div className="d-flex justify-content-end mt-xl-3 mt-md-4 mt-4">
              <Button
                className="py-2 px-4 rounded bg-white me-3 btn-secondary"
                onClick={() => navigate('/admin/job-management')}
              >
                Cancel
              </Button>
              <Button
                className={`py-2 px-4 border-0 rounded bg_primary width-rev-px-97 loader_btn text-white ${
                  jobName.trim() &&
                  status !== '' &&
                  selectedTeamLeaders.length > 0 &&
                  selectedCrew.size > 0
                    ? 'enabled-button'
                    : 'disabled-button'
                }`}
                onClick={handleSave}
                disabled={
                  !(
                    jobName.trim() !== '' &&
                    status !== '' &&
                    selectedTeamLeaders.length > 0 &&
                    selectedCrew.size > 0
                  ) || loading
                }
              >
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {' '}
                    <span className="pe-2"></span>
                    Save
                    <span className="ps-2"></span>
                  </>
                )}{' '}
              </Button>
            </div>
          </div>
          <ModalValidate
            show={modalShow}
            handleClose={() => {
              setModalShow(false);
              navigate('/admin/job-management');
            }}
            title="Job added successfully"
            confirmTitle="Add Job Notes"
            closeTitle="Back to list"
            message="You can add job notes now"
            onConfirm={handleModalConfirm}
            onClose={() => {
              setModalShow(false);
            }}
          />
        </div>
      )}
    </>
  );
}

AddJob.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default AddJob;
