import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import '../styles/fileUpload.scss';
import ImagePreviewModal from './ImagePreviewModal';
import { useState } from 'react';
import { getFileIcon, truncateFileName } from '../utils/constants';

function FilePreview({ selectedFiles, setSelectedFiles }) {
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');

  if (selectedFiles.length > 5) {
    setSelectedFiles([]);
  }
  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleImagePreview = (file) => {
    if (
      file.type.startsWith('image/') &&
      !['image/dwg', 'image/pjg'].includes(file.type)
    ) {
      setPreviewImageUrl(URL.createObjectURL(file));
      setShowImagePreviewModal(true);
    }
  };

  return (
    <div className="selected-files rounded-2" id="scrollstyle">
      <ul className="note-attachments m-0 p-2">
        {selectedFiles.map((file, index) => {
          const isImage =
            file.type.startsWith('image/') &&
            !['image/dwg', 'image/vnd.dwg'].includes(file.type);
          const fileIcon = getFileIcon(file.type);

          return (
            <li
              key={index}
              className="d-flex align-items-center justify-content-between gap-0 bg-white m-0 p-0 px-2 py-1 h-100  rounded-2"
            >
              <div
                className={`d-flex align-items-center gap-2 ${
                  isImage ? 'cursor' : ''
                }`}
                onClick={() => handleImagePreview(file)}
              >
                {isImage ? (
                  <div className="filePreview rounded-2 d-flex">
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="w-100 h-100 rounded-2"
                    />
                  </div>
                ) : (
                  <div className="filePreview rounded-2 d-flex">
                    <img src={fileIcon} className="w-100 h-100" />
                  </div>
                )}
                <p className="m-0 flieName font-size-14">
                  {truncateFileName(file.name)}
                </p>
              </div>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => removeFile(index)}
                className="removeFileIcon"
              />
            </li>
          );
        })}
      </ul>
      <ImagePreviewModal
        url={previewImageUrl}
        show={showImagePreviewModal}
        handleClose={() => setShowImagePreviewModal(false)}
        isPreview
      />
    </div>
  );
}

FilePreview.propTypes = {
  selectedFiles: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
};

export default FilePreview;
