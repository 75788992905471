import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import sort from '../assets/images/sort.svg';
import { deleteJob } from './api';
import Trash from '../assets/images/trash.svg';
import PropTypes from 'prop-types';
import ListHeader from './ListHeader';
import Pagination from './Pagination';
import ModalValidate from './ModalValidate'; // Import ModalValidate component
import '../styles/Job.scss';
import { Link } from 'react-router-dom';

function JobList({ crewData, isSearching, onDeleteCrewMember }) {
  const [sortType, setSortType] = useState(null);
  const [sortOrder, setSortOrder] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change the number of items per page as needed
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalMessage, setModalMessage] = useState('');
  const [modalConfirmTitle, setModalConfirmTitle] = useState(''); // Hold _id for deletion
  const [confirmButtonLabel, setConfirmButtonLabel] = useState(''); // Label for confirmation button
  const [deletingJob, setDeletingJob] = useState(null); // State for animating deletion
  // const [setItems] = useState(crewData); // Track the items in the state
  const listRef = useRef(null);

  const toggleSortByStatus = () => {
    setSortType('status');
    setSortOrder((prevSortOrder) => !prevSortOrder);
  };

  const toggleSortByName = () => {
    setSortType('name');
    setSortOrder((prevSortOrder) => !prevSortOrder);
  };

  const sortedJobData = crewData.slice().sort((a, b) => {
    if (sortType === 'status') {
      // Sorting by status
      if (sortOrder) {
        // Ascending order
        if (a.status === 'active' && b.status !== 'active') return -1;
        if (a.status !== 'active' && b.status === 'active') return 1;
        return 0; // Keep the order unchanged for same status or both inactive
      } else {
        // Descending order
        if (a.status === 'active' && b.status !== 'active') return 1;
        if (a.status !== 'active' && b.status === 'active') return -1;
        return 0; // Keep the order unchanged for same status or both inactive
      }
    } else if (sortType === 'name') {
      // Sorting by name
      return sortOrder
        ? a.name.localeCompare(b.name) // Ascending order
        : b.name.localeCompare(a.name); // Descending order
    } else {
      // Default sorting: prioritize 'active' status
      if (a.status === 'active' && b.status !== 'active') return -1;
      if (a.status !== 'active' && b.status === 'active') return 1;
      return 0; // Keep the order unchanged for same status or both inactive
    }
  });

  // Pagination and other functions remain unchanged as per your implementation

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedJobData.slice(indexOfFirstItem, indexOfLastItem);

  const handleDeleteCrewMember = (crewMember) => {
    setModalMessage(`Are you sure you want to delete  ${crewMember.name}?`);
    setModalConfirmTitle(crewMember._id); // Set _id for deletion
    setConfirmButtonLabel('Delete'); // Set button label
    setShowModal(true);
  };

  const onDeleteConfirmed = async () => {
    try {
      await deleteJob(modalConfirmTitle); // Use modalConfirmTitle which should be the _id
      setDeletingJob(modalConfirmTitle);
      onHideModalValidate();
      onDeleteCrewMember(modalConfirmTitle); // Pass _id to onDeleteCrewMember
    } catch (error) {
      console.error('Error deleting crew member:', error);
      setDeletingJob(null);
    }
  };

  const onHideModalValidate = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const paginate = (pageNumber) => {
      if (isSearching) {
        setCurrentPage(1);
      } else {
        setCurrentPage(pageNumber);
      }
    };

    paginate(currentPage); // Pass currentPage to paginate function
  }, [currentPage, isSearching]); // Ensure all dependencies are listed here

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };

  return (
    <div>
      <ul className="pe-3 bg_crew_list mt-4 mt-md-4 mb-1 py-3 rounded">
        <li className="row col-12">
          <div className="col-md-6 col-9">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-6">
                <ListHeader title="Job Name" onSortClick={toggleSortByName} />
              </div>
              <div className="col-xl-3 col-md-6 col-6">
                <div className="d-flex align-items-center justify-content-xl-center justify-content-center justify-content-md-end">
                  <h2 className="font-size-16 fw-bold mb-0 me-2">Status</h2>
                  <img
                    src={sort}
                    className="cursor_pointer"
                    onClick={toggleSortByStatus}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-3 text-end">
            <h2 className="font-size-16 fw-bold mb-0">Action</h2>
          </div>
        </li>
      </ul>
      <ul className="pe-3 list scroll_bar" id="scrollstyle" ref={listRef}>
        {currentItems.length > 0 ? (
          currentItems.map((crewMember) => (
            <div
              key={crewMember._id}
              className={`job-item ${
                deletingJob === crewMember._id ? 'deleting' : ''
              }`}
            >
              <li className="row">
                <div className="col-xl-6 col-md-9 col-9 mt-2">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4 col-6">
                      <h2 className="font-size-16 crew_name_color">
                        {crewMember.name}
                      </h2>
                    </div>
                    <div className="col-xl-3 col-md-5 col-6">
                      <h2
                        className={`font-size-16 crew_name_color text-center py-1 rounded-pill text-capitalize ${
                          crewMember.status === 'complete'
                            ? 'text_completed completed-styles'
                            : 'text_success success-styles'
                        }`}
                      >
                        {crewMember.status}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-3 col-3 text-end d-flex justify-content-end align-items-center flex-row pe-4">
                  <Link
                    className="link"
                    to={`/admin/job-management/edit-job/${crewMember._id}`}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="edit_icon_color cursor_pointer font-size-18 jobEdit"
                    />
                  </Link>
                  <img
                    src={Trash}
                    className="width-rev-px-22 height-rev-px-22 ms-2 cursor_pointer"
                    alt="Delete"
                    onClick={() => handleDeleteCrewMember(crewMember)}
                  />
                </div>
              </li>
              <hr className="hr_line my-1 width-rev-112 width-rev-md-108 width-rev-xl-104" />
            </div>
          ))
        ) : (
          <p className="d-flex align-items-center justify-content-center mt-5 font-size-18 opacity-75 fw-semibold">
            No data available
          </p>
        )}
      </ul>

      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(sortedJobData.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />

      {/* ModalValidate Component */}
      <ModalValidate
        show={showModal}
        handleClose={onHideModalValidate}
        message={modalMessage}
        closeTitle="Cancel"
        confirmTitle={confirmButtonLabel} // Use confirmButtonLabel for the confirmation button
        onConfirm={onDeleteConfirmed} // Pass onDeleteConfirmed directly
        secondaryBtn
      />
    </div>
  );
}

JobList.propTypes = {
  crewData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  isSearching: PropTypes.bool.isRequired,
  onEditCrewMember: PropTypes.func.isRequired,
  onDeleteCrewMember: PropTypes.func.isRequired,
};

export default JobList;
