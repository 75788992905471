// Button.js
import React from 'react';
import PropTypes from 'prop-types';

function Button({ className, onClick, children, disabled, title }) {
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Button;
