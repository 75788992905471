// src/constants.js

import pdf from '../assets/fileIconPreviews/pdf.svg';
import excel from '../assets/fileIconPreviews/excel.svg';
import dwg from '../assets/fileIconPreviews/dwg.svg';
import word from '../assets/fileIconPreviews/word.svg';
import file from '../assets/fileIconPreviews/file.svg';

// eslint-disable-next-line no-undef
export const baseURL =
  process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3001/api';

export const getFileExtension = (filename) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const isImageFile = (extension) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  return imageExtensions.includes(extension.toLowerCase());
};

const MAX_FILENAME_LENGTH = 10;

export const truncateFileName = (name) => {
  const lastDotIndex = name.lastIndexOf('.');

  if (lastDotIndex === -1) {
    return name;
  }

  const fileName = name.slice(0, lastDotIndex);
  const fileExtension = name.slice(lastDotIndex + 1);

  if (fileName.length < MAX_FILENAME_LENGTH) {
    return name;
  }

  const truncatedStart = fileName.slice(0, 3);
  const truncatedEnd = fileName.slice(-3);
  return `${truncatedStart}...${truncatedEnd}.${fileExtension}`;
};

export const getFileIcon = (fileType) => {
  switch (fileType) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/doc':
    case 'application/ms-doc':
      return word;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return excel;
    case 'application/pdf':
      return pdf;
    case 'text/plain':
      return file;
    case 'application/acad':
    case 'application/x-acad':
    case 'application/autocad_dwg':
    case 'image/vnd.dwg':
      return dwg;
    default:
      return file; // Default icon for other file types
  }
};

export const getMimeTypeFromExtension = (extension) => {
  switch (extension.toLowerCase()) {
    case 'doc':
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'pdf':
      return 'application/pdf';
    case 'txt':
      return 'text/plain';
    case 'dwg':
      return 'image/vnd.dwg';
    default:
      return 'application/octet-stream'; // Generic binary file MIME type
  }
};

export const getFileName = (fileUrl) => {
  const fileNameWithPrefix = fileUrl.split('/').pop();
  const decodedFileName = decodeURIComponent(fileNameWithPrefix);
  const cleanedFileName = decodedFileName.replace(/^[a-z0-9-]+__/i, '');
  return cleanedFileName;
};

export const handleFileDownload = (attachment) => {
  fetch(attachment)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = getFileName(attachment) || 'download';
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    })
    .catch((error) => console.error('Download failed:', error));
};
