import React, { useState, useEffect } from 'react';
import PopupModal from './Modal';
import CrewList from './CrewList';
import BoxTitle from './BoxTitle';
import Button from './Button';
import SearchBar from './Search';
import { fetchCrewMembers, addCrewMember } from './api';
import LoadingSpinner from './LoadingSpinner';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CrewManagement() {
  const [crewData, setCrewData] = useState([]);
  const [filteredCrewData, setFilteredCrewData] = useState([]);
  const [showAddCrewModal, setShowAddCrewModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchCrewMembers();
      setCrewData(data);
      setFilteredCrewData(data); // Initialize filtered crew data with all crew members
    } catch (error) {
      console.error('Error fetching crew data:', error);
      setError('Failed to fetch crew data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (searchInput) => {
    const lowerCaseSearchInput = searchInput.toLowerCase();

    const filtered = crewData.filter((crewMember) => {
      const fullName =
        `${crewMember.firstName} ${crewMember.lastName}`.toLowerCase();
      return (
        crewMember.firstName.toLowerCase().includes(lowerCaseSearchInput) ||
        crewMember.lastName.toLowerCase().includes(lowerCaseSearchInput) ||
        fullName.includes(lowerCaseSearchInput)
      );
    });

    setIsSearching(true);
    setFilteredCrewData(filtered);
  };

  const handleAddCrew = async (newCrewData) => {
    setLoading(true);
    setError(null);
    try {
      await addCrewMember(newCrewData);
      fetchData();
      setSearchInput('');
      setShowAddCrewModal(false);
    } catch (err) {
      console.error('Error adding crew member:', err);
      setError('Failed to add crew member. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditCrewMember = () => {
    fetchData();
    setSearchInput('');
  };

  const handleDeleteCrewMember = (id) => {
    const updatedCrewData = crewData.filter(
      (crewMember) => crewMember.id !== id
    );
    setCrewData(updatedCrewData);
    setFilteredCrewData(updatedCrewData);
    fetchData();
    setSearchInput('');
  };

  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
        <BoxTitle
          className="font-size-18 fw-bold mb-0 ms-4 ps-2"
          title="All Crew"
        />
        <div className="d-flex flex-row gap-4 search">
          <SearchBar
            className="py-2 h-100"
            placeholder="Search by crew name"
            onSearch={handleSearch}
            onBlur={() => setIsSearching(false)}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <Button
            className="border-0 rounded bg_primary text-white fw-bold d-flex align-items-center gap-1 px-3 addBtn"
            onClick={() => setShowAddCrewModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="pe-1" />
            Add Crew
          </Button>
        </div>
      </div>
      <div className="loader colorLoader">{loading && <LoadingSpinner />}</div>
      {error && <div className="alert alert-danger">{error}</div>}
      {!loading && !error && (
        <>
          <CrewList
            crewData={filteredCrewData}
            onEditCrewMember={handleEditCrewMember}
            onDeleteCrewMember={handleDeleteCrewMember}
            isSearching={isSearching}
          />
        </>
      )}
      <PopupModal
        show={showAddCrewModal}
        handleClose={() => setShowAddCrewModal(false)}
        onAddCrew={handleAddCrew}
        title="Add Crew"
        buttonText="Add"
      />
    </div>
  );
}

export default CrewManagement;
