// import { faClock} from '@fortawesome/free-regular-svg-icons';
// import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import crewActive from '../assets/images/crewActive.svg';
import crew from '../assets/images/crew.svg';
import jobActive from '../assets/images/jobActive.svg';
import job from '../assets/images/job.svg';
import shiftActive from '../assets/images/shiftActive.svg';
import shift from '../assets/images/shift.svg';

const SideBarLinks = () => {
  const crewUrl = '/admin/crew-management';
  const orderInfoUrl = '/admin/job-management';
  const reportInfoUrl = '/admin/shift-report';

  const Links = [
    {
      to: crewUrl,
      text: 'Crew Management',
      image: <img src={crew} alt="sidebar link" className="me-2" />,
      activeImg: <img src={crewActive} alt="sidebar link" className="me-2" />,
    },
    {
      to: orderInfoUrl,
      text: 'Job Management',
      image: <img src={job} alt="sidebar link" className="me-2" />,
      activeImg: <img src={jobActive} alt="sidebar link" className="me-2" />,
    },
    {
      to: reportInfoUrl,
      text: 'Shift Report',
      image: <img src={shift} alt="sidebar link" className="me-2" />,
      activeImg: <img src={shiftActive} alt="sidebar link" className="me-2" />,
    },
  ];

  return Links;
};

export default SideBarLinks;
