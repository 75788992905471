import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from './Button';
import '../styles/Crew.scss';
import { checkEmailExists } from './api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function PopupModal({
  show,
  handleClose,
  onAddCrew,
  formValues,
  title,
  isEditing,
}) {
  const [formData, setFormData] = useState({
    ...formValues,
    roles: formValues.roles || [],
  });
  const [showPassword, setShowPassword] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [originalEmail, setOriginalEmail] = useState(
    isEditing ? formValues.email : ''
  );

  const emailPattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.[a-z]{2,}/i;

  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d@$!%*?&/\\|<>,.^~`!@#%&_+\-=[\]{};':"\\|,.<>?]{8,}$/;

  useEffect(() => {
    if (show) {
      setFormData({
        ...formValues,
        roles: formValues.roles || [],
        password: '',
      });
      setValidationErrors({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      });
      setIsSubmitted(false);
      if (isEditing) {
        setOriginalEmail(formValues.email);
      }
    }
  }, [formValues, show, isEditing]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setFormData((prevData) => {
        const roles = checked
          ? [...prevData.roles, { role: value }]
          : prevData.roles.filter((roleObj) => roleObj.role !== value);
        return { ...prevData, roles };
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Validate password field when editing and password is entered
    if (name === 'password' && isEditing && value.trim() !== '') {
      validateField(name, value, isEditing);
    }

    if (isSubmitted) {
      validateField(name, value, isEditing);
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateField(name, value, isEditing, originalEmail);
  };

  const validateField = async (name, value, isEditing, originalEmail) => {
    let error = '';

    if (name === 'firstName' || name === 'lastName') {
      const trimmedValue = value.trim();
      if (!trimmedValue) {
        error = 'This field is required';
      } else if (trimmedValue.length < 3) {
        error = 'It must be at least 3 characters';
      }
    }

    if (name === 'email') {
      if (formData.roles.length > 0 && !value.trim()) {
        error = 'Email is required';
      } else if (value && !emailPattern.test(value)) {
        error = 'Invalid email address';
      } else if (
        value &&
        (!isEditing || (isEditing && value.trim() !== originalEmail))
      ) {
        const emailExists = await checkEmailExists(value.trim());
        if (emailExists) {
          error = 'Email already exists';
        }
      }
    }

    if (name === 'password' && formData.roles.length > 0) {
      if (!isEditing && !value.trim()) {
        error = 'Password is required';
      } else if (value && value.length < 8) {
        error = 'It must be at least 8 characters';
      } else if (value && !passwordPattern.test(value)) {
        error =
          'Password must include at least one lowercase letter, one uppercase letter, one number, and one special character';
      }
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const validateForm = async (isEditing, formData, originalEmail) => {
    const errors = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      roleError: '',
    };

    const trimmedFirstName = formData.firstName.trim();
    const trimmedLastName = formData.lastName.trim();
    const trimmedEmail = formData.email.trim();
    const trimmedPassword = formData.password.trim();

    if (!trimmedFirstName) {
      errors.firstName = 'This field is required';
    } else if (trimmedFirstName.length < 3) {
      errors.firstName = 'It must be at least 3 characters';
    }

    if (!trimmedLastName) {
      errors.lastName = 'This field is required';
    } else if (trimmedLastName.length < 3) {
      errors.lastName = 'It must be at least 3 characters';
    }

    if (formData.roles.length > 0 && !trimmedEmail) {
      errors.email = 'Email address is required';
    } else if (trimmedEmail && !emailPattern.test(trimmedEmail)) {
      errors.email = 'Invalid email address';
    } else if (
      trimmedEmail &&
      (!isEditing || (isEditing && trimmedEmail !== originalEmail))
    ) {
      const emailExists = await checkEmailExists(trimmedEmail);
      if (emailExists) {
        errors.email = 'Email already exists';
      }
    }

    if (formData.roles.length > 0) {
      if (!isEditing && !trimmedPassword) {
        errors.password = 'Password is required';
      } else if (trimmedPassword && trimmedPassword.length < 8) {
        errors.password = 'It must be at least 8 characters';
      } else if (trimmedPassword && !passwordPattern.test(trimmedPassword)) {
        errors.password =
          'Password must include at least one lowercase letter, one uppercase letter, one number, and one special character';
      }
    }

    setValidationErrors(errors);
    return errors;
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);

    const trimmedFormData = {
      ...formData,
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim(),
      password: formData.password.trim(),
    };

    setFormData(trimmedFormData);

    const errors = await validateForm(
      isEditing,
      trimmedFormData,
      originalEmail
    );

    if (
      !errors.firstName &&
      !errors.lastName &&
      !errors.email &&
      (!errors.password || isEditing)
    ) {
      const { password, ...otherData } = trimmedFormData;
      const dataToSubmit = isEditing && !password ? otherData : trimmedFormData;
      onAddCrew(dataToSubmit);
      handleClose();
    }
  };

  const isFormValid = (isEditing) => {
    const { firstName, lastName, email, password, roles } = formData;

    if (roles.length > 0) {
      if (isEditing) {
        return firstName && lastName && email;
      }
      return firstName && lastName && email && password.length >= 8;
    } else {
      return firstName && lastName;
    }
  };

  const togglePasswordVisibility = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const hasChanges = () => {
      const { firstName, lastName, email, password, roles } = formData;
      const {
        firstName: prevFirstName,
        lastName: prevLastName,
        email: prevEmail,
        roles: prevRoles,
      } = formValues;
      const hasFirstNameChanged = firstName !== prevFirstName;
      const hasLastNameChanged = lastName !== prevLastName;
      const hasEmailChanged = email !== prevEmail;
      const hasPasswordChanged = password !== '';
      const hasRolesChanged =
        roles.length !== prevRoles.length ||
        roles.some(
          (roleObj) =>
            !prevRoles.some((prevRoleObj) => prevRoleObj.role === roleObj.role)
        );
      return (
        hasFirstNameChanged ||
        hasLastNameChanged ||
        hasEmailChanged ||
        hasPasswordChanged ||
        hasRolesChanged
      );
    };
    setIsDirty(hasChanges());
  }, [formData, formValues]);

  return (
    <Modal
      className="modal_class"
      onExited={() =>
        setFormData({ ...formValues, roles: formValues.roles || [] })
      }
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="pt-3 pb-0" closeButton></Modal.Header>
      <Modal.Title className="m-auto">{title}</Modal.Title>
      <Modal.Body className="max-width-42 px-4">
        <form>
          <div className="font-size-13 text-danger">
            *Marked fields are mandatory
          </div>
          <div className="crew_name_color fw-bold crew_form d-flex flex-column">
            <div className="d-flex gap-3">
              <div className="form-group w-100">
                <label htmlFor="firstName">First Name*</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First name"
                  required
                  maxLength={30}
                />
                {validationErrors.firstName && (
                  <div className="text_danger font-size-14 mt-1">
                    {validationErrors.firstName}
                  </div>
                )}
              </div>
              <div className="form-group w-100">
                <label htmlFor="lastName">Last Name*</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last name"
                  required
                  maxLength={30}
                />
                {validationErrors.lastName && (
                  <div className="text_danger font-size-14 mt-1 mt-1">
                    {validationErrors.lastName}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex gap-3">
              <div className="form-group w-100">
                <label htmlFor="email">
                  Email Address{formData.roles.length > 0 ? '*' : ''}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  required
                  disabled={formData.roles.length === 0}
                />
                {validationErrors.email && formData.roles.length > 0 && (
                  <div className="text_danger font-size-14 mt-1 mt-1">
                    {validationErrors.email}
                  </div>
                )}
              </div>
              <div className="form-group w-100 position-relative">
                <label htmlFor="password">
                  Password{formData.roles.length > 0 && !isEditing ? '*' : ''}
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control pe-5"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={isEditing ? handleBlur : undefined}
                  placeholder={!isEditing ? 'Enter a password' : '••••••••'}
                  required={isEditing ? false : true}
                  disabled={formData.roles.length === 0}
                />
                <div
                  className="ms-0 border-0 p-0 password-icon-crew"
                  onClick={togglePasswordVisibility}
                >
                  {!showPassword ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      style={{ color: 'lightgray' }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ color: 'lightgray' }}
                    />
                  )}
                </div>
                {validationErrors.password && formData.roles.length > 0 && (
                  <div className="text_danger font-size-14 mt-1 mt-1 passwordValidation">
                    {validationErrors.password}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3">
            <label className="fw-bold">Roles</label>
            <div className="d-flex align-items-center">
              <div className="form-group fw-bold d-flex align-items-center pe-4 custom-checkbox">
                <input
                  type="checkbox"
                  id="adminRole"
                  name="role"
                  className="me-2"
                  value="admin"
                  checked={formData.roles.some(
                    (roleObj) => roleObj.role === 'admin'
                  )}
                  onChange={handleChange}
                />
                <label className="fw-normal checkbox-label" htmlFor="adminRole">
                  Admin
                </label>
              </div>
              <div className="form-group fw-bold d-flex align-items-center custom-checkbox">
                <input
                  type="checkbox"
                  id="teamLeadRole"
                  name="role"
                  className="me-2"
                  value="team leader"
                  checked={formData.roles.some(
                    (roleObj) => roleObj.role === 'team leader'
                  )}
                  onChange={handleChange}
                />
                <label
                  className="fw-normal checkbox-label"
                  htmlFor="teamLeadRole"
                >
                  Team Lead
                </label>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="justify-content-center pb-4 mt-3 gap-2">
        <Button
          className="py-2 px-4 rounded btn-secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={`py-2 px-4 border-0 rounded bg_primary loader_btn text-white ${
            isFormValid(isEditing) && isDirty ? 'opacity-100' : 'opacity-50'
          }`}
          onClick={handleSubmit}
          disabled={!isFormValid(isEditing) || !isDirty}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

PopupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onAddCrew: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string.isRequired,
      })
    ),
  }),
  title: PropTypes.string,
  isEditing: PropTypes.bool,
};

PopupModal.defaultProps = {
  formValues: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roles: [],
  },
  title: 'Modal heading',
};

export default PopupModal;
