import '../src/styles/App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import LoginPage from './pages/LoginPage/Login';
import UserProfile from './components/UserProfile';
import AddJob from './components/AddJob';
import CrewManagement from './components/CrewManagement';
import JobManagement from './components/JobManagement';
import ShiftReport from './components/ShiftReport';
import EditJob from './components/EditJob';

function App() {
  const { isLoggedIn, login } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/admin/login" />} />
      <Route path="/admin/login" element={<LoginPage onLogin={login} />} />
      <Route
        path="/admin/*"
        element={
          isLoggedIn ? (
            <UserProfile>
              <Routes>
                <Route path="crew-management" element={<CrewManagement />} />
                <Route path="job-management" element={<JobManagement />} />
                <Route path="job-management/add-job" element={<AddJob />} />
                <Route
                  path="job-management/edit-job/:id"
                  element={<EditJob />}
                />
                <Route path="shift-report" element={<ShiftReport />} />
              </Routes>
            </UserProfile>
          ) : (
            <Navigate to="/admin/login" />
          )
        }
      />
    </Routes>
  );
}

export default App;
