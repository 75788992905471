import React from 'react';
import { NavLink } from 'react-router-dom';
import SideBarLinks from './SideBarLinks';
import '../styles/Sidebar.scss';

function UserSideBar() {
  const sideBarLinks = SideBarLinks();

  // Initialize state to track the active link

  return (
    <div className="account-menu pl2 header-conatiner-logo">
      <div className="user mt2">
        <div className="profile font-size-02">
          {sideBarLinks.map((link, index) => (
            <div key={index}>
              <div className="child-links ps-1">
                <NavLink
                  to={link.to}
                  className={({ isActive }) =>
                    `d-flex userprofile ${isActive ? 'active' : ''}`
                  }
                >
                  {({ isActive }) => (
                    <div className="d-flex align-items-center ">
                      {isActive ? link.activeImg : link.image}
                      <b className={isActive ? 'pipe' : ''}></b>
                      <span className="userprofile-name fw-bold">
                        {link.text}
                      </span>
                    </div>
                  )}
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserSideBar;
