import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../styles/imagePreviewModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import download from '../assets/fileIconPreviews/download.svg';
import { handleFileDownload } from '../utils/constants';

const ImagePreviewModal = ({ show, handleClose, url, isPreview }) => {
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [isLargeImage, setIsLargeImage] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      setIsLargeImage(
        img.height > viewportHeight - 110 || img.width > viewportWidth - 40
      );
    };
    img.src = url;
  }, [url]);

  const resetState = useCallback(() => {
    setRotation(0);
    setScale(1);
  }, []);

  useEffect(() => {
    if (!show) {
      resetState();
    }
  }, [show, resetState]);

  const handleRotate = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.1));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="imagePreviewModal modal-xl"
    >
      <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
      <Modal.Body className={isLargeImage ? 'large-image' : ''}>
        <div className="imagePreviewModal__container">
          <img
            src={url}
            alt="Preview"
            style={{
              transform: `rotate(${rotation}deg) scale(${scale})`,
              transition: 'transform 0.3s ease',
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="image-controls">
          <Button onClick={handleRotate} title="rotate" variant="light">
            <FontAwesomeIcon icon={faRotateRight} size={'1x'} color="#162574" />
          </Button>
          <Button onClick={handleZoomOut} title="zoom out" variant="light">
            <FontAwesomeIcon
              icon={faMagnifyingGlassMinus}
              size={'1x'}
              color="#162574"
            />
          </Button>
          <Button onClick={handleZoomIn} title="zoom in" variant="light">
            <FontAwesomeIcon
              icon={faMagnifyingGlassPlus}
              size={'1x'}
              color="#162574"
            />
          </Button>
          {!isPreview && (
            <Button
              onClick={() => handleFileDownload(url)}
              title="download"
              variant="light"
            >
              <img src={download} alt="download" className="downloadFile" />
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ImagePreviewModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  url: PropTypes.string,
  isPreview: PropTypes.bool,
};

export default ImagePreviewModal;
