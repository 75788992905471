// LoadingSpinner.js

import React from 'react';
import '../styles/Job.scss';
function LoadingSpinner() {
  return (
    <div className="spinner_loader">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="spinner-border" role="status"></div>
        <div className="mt-1 load_title">
          <span className="text-black">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
