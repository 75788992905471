import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import Trash from '../assets/images/trash.svg';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import PopupModal from './Modal';
import ListHeader from './ListHeader';
import Pagination from './Pagination';
import ModalValidate from './ModalValidate'; // Import ModalValidate
import { editCrewMember, deleteCrewMember } from './api';
import '../styles/Crew.scss';

function CrewList({
  crewData,
  isSearching,
  onEditCrewMember,
  onDeleteCrewMember,
}) {
  const [selectedCrewMember, setSelectedCrewMember] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for Delete Modal
  const [crewMemberToDelete, setCrewMemberToDelete] = useState(null); // State to hold crew member to delete
  const [deletingCrewMember, setDeletingCrewMember] = useState(null); // State for animating deletion
  const [sorted, setSorted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change the number of items per page as needed
  const listRef = useRef(null);

  const handleEditClick = (crewMember) => {
    const editedCrewMember = { ...crewMember };
    // editedCrewMember.password = '';
    setSelectedCrewMember(editedCrewMember);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedCrewMember(null);
    setShowEditModal(false);
  };

  const toggleSort = () => {
    setSorted((prevSorted) => !prevSorted);
  };

  const sortedCrewData = [...crewData]
    .filter((crewMember) => !crewMember.isDeleted)
    .sort((a, b) => {
      const sortOrder = sorted ? 1 : -1;
      return a.firstName.localeCompare(b.firstName) * sortOrder;
    });

  async function handleEditSubmit(formData) {
    setLoading(true);
    try {
      const response = await editCrewMember(formData._id, formData);
      onEditCrewMember(response.data);
      handleCloseEditModal();
    } catch (error) {
      console.error('Error editing crew member:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteCrewMember = (crewMember) => {
    setCrewMemberToDelete(crewMember);
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    setDeletingCrewMember(crewMemberToDelete._id); // Start the delete animation
    setShowDeleteModal(false); // Close the modal first

    setTimeout(async () => {
      // Wait for the animation to complete
      try {
        await deleteCrewMember(crewMemberToDelete._id);
        onDeleteCrewMember(crewMemberToDelete._id);
        setCrewMemberToDelete(null);
      } catch (error) {
        console.error('Error deleting crew member:', error);
      } finally {
        setLoading(false);
        setDeletingCrewMember(null);
      }
    }, 500); // Match this duration to the CSS transition time
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedCrewData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const paginate = (pageNumber) => {
      if (isSearching) {
        setCurrentPage(1);
      } else {
        setCurrentPage(pageNumber);
      }
    };

    paginate(currentPage); // Pass currentPage to paginate function
  }, [currentPage, isSearching]); // Ensure all dependencies are listed here

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };

  return (
    <>
      <ul className="pe-3 bg_crew_list mt-4 mb-2 py-3 rounded">
        <li className="row col-12">
          <div className="col-6">
            <ListHeader title="Crew Name" onSortClick={toggleSort} />
          </div>
          <div className="col-6 text-end">
            <h2 className="font-size-16 fw-bold mb-0">Action</h2>
          </div>
        </li>
      </ul>
      <ul className="pe-3 list scroll_bar" id="scrollstyle" ref={listRef}>
        {currentItems.length > 0 ? (
          currentItems.map((crewMember) => (
            <div
              key={crewMember._id}
              className={`crew-item ${
                deletingCrewMember === crewMember._id ? 'deleting' : ''
              }`}
            >
              <li className="row">
                <div className="col-6 mt-2">
                  <h2 className="font-size-16 crew_name_color">
                    {crewMember.firstName} {crewMember.lastName}
                  </h2>
                </div>
                <div className="col-6 text-end d-flex justify-content-end align-items-center flex-row pe-4">
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="edit_icon_color cursor_pointer font-size-18"
                    onClick={() => handleEditClick(crewMember)}
                  />
                  <img
                    src={Trash}
                    className="width-rev-px-22 height-rev-px-22 ms-2 cursor_pointer"
                    alt="Delete"
                    onClick={() => handleDeleteCrewMember(crewMember)}
                  />
                </div>
              </li>
              <hr className="hr_line my-2 width-rev-112 width-rev-md-108 width-rev-xl-104" />
            </div>
          ))
        ) : (
          <p className="d-flex align-items-center justify-content-center mt-5 font-size-18 opacity-75 fw-semibold">
            No data available
          </p>
        )}
      </ul>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(sortedCrewData.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />

      {/* Edit Modal */}
      {showEditModal && (
        <PopupModal
          show={showEditModal}
          handleClose={handleCloseEditModal}
          title="Edit Crew"
          buttonText={loading ? 'Saving...' : 'Save'}
          formValues={selectedCrewMember}
          onAddCrew={handleEditSubmit}
          disableButton={loading}
          isEditing={true}
        />
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <ModalValidate
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          message={`Are you sure you want to delete ${crewMemberToDelete?.firstName} ${crewMemberToDelete?.lastName}?`}
          onConfirm={handleDeleteConfirm}
          closeTitle="Cancel"
          confirmTitle="Delete"
          secondaryBtn
        />
      )}
    </>
  );
}

CrewList.propTypes = {
  crewData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      isDeleted: PropTypes.bool.isRequired,
    })
  ).isRequired,
  isSearching: PropTypes.bool.isRequired,
  onEditCrewMember: PropTypes.func.isRequired,
  onDeleteCrewMember: PropTypes.func.isRequired,
};

export default CrewList;
