import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo, userLogout } from './api'; // Import userLogout from your api file
import UserSideBar from './UserSideBar';
import NavBar from './NavBar';
import User from '../assets/images/user.svg';
import Logout from '../assets/images/log-out.svg';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../styles/Sidebar.scss';
import ModalValidate from './ModalValidate';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';

function UserProfile({ children }) {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [userData, setUserData] = useState(null);
  const [logoutConfirmationModalOpen, setLogoutConfirmationModalOpen] =
    useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleToggleOffcanvas = () => setShowOffcanvas((prev) => !prev);

  const handleLogout = async () => {
    try {
      await userLogout(); // Call the logout API
      navigate('/admin/login'); // Redirect to login page
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const fetchUserInfo = async () => {
    const response = await getUserInfo();
    setUserData(response);
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const isAddJobPage = location.pathname === '/admin/job-management/add-job';
  const isEditJobPage = location.pathname.includes('edit-job');

  const navigateToJobManagement = () => {
    navigate('/admin/job-management'); // Navigate back to the job management route
  };

  // Define the route for which you don't want to apply the border_box class
  const noBorderBoxRoutes = [
    '/admin/job-management/add-job',
    '/admin/job-management/edit-job/:id',
  ];

  // Check if the current route matches any of the noBorderBoxRoutes patterns
  const isNoBorderBoxRoute = noBorderBoxRoutes.some((route) => {
    const routeRegex = new RegExp(`^${route.replace('/:id', '/[^/]+')}$`);
    return routeRegex.test(location.pathname);
  });

  return (
    <>
      <div className="container-fluid overflow-hidden">
        <div className="row gx-5 sidebar_w">
          {/* Sidebar for larger screens */}
          <div className="col-3 bg-white pe-0 border-right d-none d-md-block width-20 z_index_3">
            <div className="d-flex flex-column h-100">
              <NavBar />
              <UserSideBar />
              <div style={{ flex: '1' }}></div>
              {userData && (
                <div className="d-flex flex-column mb-3 ps-3">
                  <div className="pe-1 d-flex align-items-center gap-1">
                    <img
                      src={User}
                      className="width-rev-px-30 height-rev-px-30 rounded-pill"
                      alt="User Avatar"
                    />
                    <h1 className="font-size-16 m-0 adminName">
                      {userData.firstName} {userData.lastName}
                    </h1>
                  </div>
                </div>
              )}
              <div
                className="d-flex align-items-center log_out ps-3 mb-4"
                onClick={() => setLogoutConfirmationModalOpen(true)}
              >
                <img src={Logout} alt="logout" />
                <h6 className="mb-0 ps-2 fw-bold">Logout</h6>
              </div>
            </div>
          </div>

          {/* Offcanvas menu for smaller screens */}
          <div className="col-12 d-block d-md-none bg-white">
            <div className="row align-items-center">
              <div className="col-6">
                <Navbar bg="light" expand="lg" className="">
                  <Container fluid className="p-0">
                    <Navbar.Toggle
                      aria-controls="offcanvasNavbar"
                      onClick={handleToggleOffcanvas}
                      className="border-0 p-0"
                    />
                    <Navbar.Collapse
                      id="offcanvasNavbar"
                      onHide={handleCloseOffcanvas}
                    ></Navbar.Collapse>
                  </Container>
                </Navbar>
                <Offcanvas
                  show={showOffcanvas}
                  onHide={handleCloseOffcanvas}
                  scroll
                  backdrop="false"
                  placement="start"
                  className="w-75 d-block d-md-none pt-0"
                >
                  <Offcanvas.Header
                    className="mt-2 me-2"
                    closeButton
                  ></Offcanvas.Header>
                  <Offcanvas.Body className="pe-0">
                    <NavBar />
                    <UserSideBar />
                    <div
                      className="d-flex align-items-center logout_mobile ms-2"
                      onClick={() => setLogoutConfirmationModalOpen(true)}
                    >
                      <img src={Logout} alt="logout" />
                      <h6 className="mb-0 ps-2">Logout</h6>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
          </div>

          {/* Main content area */}
          <div className="col-12 col-md-9 mt-0 px-md-0 px-0 width-80 scroll_section">
            {/* Routes for different sections */}
            <div className="px-0 px-md-4 mb-4 me-md-2 mt-lg">
              {(isAddJobPage || isEditJobPage) && (
                <div
                  className="d-flex align-items-center mb-3 cursor_pointer backJobNavigation"
                  onClick={navigateToJobManagement}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <h6 className="ps-2 m-0 font-size-14 fw-bold">Back to Job</h6>
                </div>
              )}
              <div
                className={`p-xl-4 p-md-3 p-4 position-relative ${
                  !isNoBorderBoxRoute ? 'border_box' : 'job_border_box'
                }`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
        <ModalValidate
          show={logoutConfirmationModalOpen}
          handleClose={() => setLogoutConfirmationModalOpen(false)}
          title=""
          closeTitle="Cancel"
          confirmTitle="Yes"
          message="Are you sure you want to logout?"
          onConfirm={handleLogout}
          onClose={() => setLogoutConfirmationModalOpen(false)}
          secondaryBtn
          className
        />
      </div>
      {/* )} */}
    </>
  );
}

UserProfile.propTypes = {
  children: PropTypes.node,
};

export default UserProfile;
