import React, { useState, useEffect, useRef } from 'react';
import '../styles/Job.scss';
import ListHeader from './ListHeader';
import SearchBar from './Search';
import BoxTitle from './BoxTitle';
import Button from './Button';
import {
  deleteNote,
  editJob,
  editNote,
  fetchUserByRoles,
  getNotes,
  getSingleJob,
  postNote,
} from './api';
import JobNote from '../assets/images/job-notes.svg';
import DeleteNote from '../assets/images/job-delete.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import ModalValidate from './ModalValidate';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import '../styles/MultiSelectDropdown.scss';
import FileUploadModal from './FileUploadModal';
import FilePreview from './FilePreview';
import NoteAttachments from './NoteAttachments';

const EditJob = () => {
  const [initialJobData, setInitialJobData] = useState(null);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [crewMember, setCrewMember] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState(new Set());
  const [jobName, setJobName] = useState('');
  const [status, setStatus] = useState('');
  const [jobNote, setJobNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [crewData, setCrewData] = useState([]);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [noteToDeleteId, setNoteToDeleteId] = useState(null); // Track which note is being deleted
  const [editMode, setEditMode] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [selectedTeamLeaders, setSelectedTeamLeaders] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [originalNotes, setOriginalNotes] = useState({});
  const [showAttachFileModal, setShowAttachFileModal] = useState(false);
  const [showEditAttachFileModal, setShowEditAttachFileModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editSelectedFiles, setEditSelectedFiles] = useState([]);
  const [postingNote, setPostingNote] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const [currentEditingNote, setCurrentEditingNote] = useState(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const textareaRefs = useRef({});

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    notes.forEach((note) => {
      if (editMode[note._id]) {
        adjustTextareaHeight(textareaRefs.current[note._id]);
      }
    });
  }, [editMode, notes]);

  const handleOpenAttachFileModal = () => setShowAttachFileModal(true);
  const handleCloseAttachFileModal = () => setShowAttachFileModal(false);

  const handleOpenEditAttachFileModal = () => {
    setShowEditAttachFileModal(true);
  };
  const handleCloseEditAttachFileModal = () =>
    setShowEditAttachFileModal(false);

  const handleFileSelect = (file) => {
    setSelectedFiles(file);
  };

  const handleEditFileSelect = (noteId, files) => {
    setEditSelectedFiles(files);
    setCurrentEditingNote(noteId);
  };

  const handleOptionClick = (leader) => {
    const leaderId = leader._id;

    const isLeaderSelected = selectedTeamLeaders.some(
      (selectedLeader) => selectedLeader.teamLeadId === leaderId
    );

    if (isLeaderSelected) {
      setSelectedTeamLeaders(
        selectedTeamLeaders.filter(
          (selectedLeader) => selectedLeader.teamLeadId !== leaderId
        )
      );
    } else {
      if (selectedTeamLeaders.length < 4) {
        setSelectedTeamLeaders([
          ...selectedTeamLeaders,
          {
            teamLeadId: leaderId,
            name: leader.firstName + ' ' + leader.lastName,
          },
        ]);
      } else {
        alert('You can select a maximum of 4 team leaders.');
      }
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { id } = useParams();
  const getJobById = async () => {
    setLoading(true);
    const response = await getSingleJob(id);
    setInitialJobData(response);
    setLoading(false);
  };

  useEffect(() => {
    getJobById();
  }, []);

  const fetchTeamLeaders = async () => {
    try {
      const response = await fetchUserByRoles();
      setTeamLeaders(response.teamLeaders);
      setCrewMember(response.crewMembers);
      setCrewData(response.crewMembers);
    } catch (error) {
      console.error('Error fetching crew data:', error);
    }
  };

  useEffect(() => {
    fetchTeamLeaders();
  }, []);

  const fetchNotes = async () => {
    const response = await getNotes(id);
    setNotes(response);
  };

  const handleSearch = (searchInput) => {
    const lowerCaseSearchInput = searchInput.toLowerCase();

    const filtered = crewData.filter((crewMember) => {
      const fullName =
        `${crewMember.firstName} ${crewMember.lastName}`.toLowerCase();
      return (
        crewMember.firstName.toLowerCase().includes(lowerCaseSearchInput) ||
        crewMember.lastName.toLowerCase().includes(lowerCaseSearchInput) ||
        fullName.includes(lowerCaseSearchInput)
      );
    });

    setCrewMember(filtered);
  };

  const formatCrewMembers = () => {
    const formattedCrewMembers = [];
    selectedCrew.forEach((crewId) => {
      const crew = crewMember.find((member) => member._id === crewId);
      if (crew) {
        formattedCrewMembers.push({
          crewId: crew._id,
          name: `${crew.firstName} ${crew.lastName}`,
          _id: crew._id,
        });
      }
    });
    return formattedCrewMembers;
  };

  const formatTeamLeadIds = () => {
    return selectedTeamLeaders;
  };

  const handlePostJobNote = async () => {
    if (!jobNote.trim() && selectedFiles.length === 0) {
      return;
    }

    try {
      setPostingNote(true);
      const response = await postNote(id, jobNote, selectedFiles);
      const newNote = {
        ...response,
        attachments: response.attachments || [],
      };
      setNotes([...notes, newNote]); // Update notes state with the new note
      fetchNotes();
      setJobNote('');
      setSelectedFiles([]); // Clear the selected files after submission
    } catch (error) {
      console.error('Error posting note:', error);
    } finally {
      setPostingNote(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const jobData = {
      name: jobName,
      teamLeadIds: formatTeamLeadIds(),
      status,
      crewMembers: formatCrewMembers(),
    };

    await editJob(id, jobData);
    setEditModalShow(true);
    setLoading(false);
  };

  const openDeleteConfirmationModal = (noteId) => {
    setDeleteConfirmationModalOpen(true);
    setNoteToDeleteId(noteId);
  };

  // Function to handle closing the delete confirmation modal
  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationModalOpen(false);
    setNoteToDeleteId(null);
  };

  const handleDeleteNote = async (noteId) => {
    // Close the modal
    closeDeleteConfirmationModal();

    await deleteNote(id, noteId);
    // Update the UI to reflect the deletion
    setNotes(notes.filter((note) => note._id !== noteId));
  };

  const handleEditNote = (noteId) => {
    const noteToEdit = notes.find((note) => note._id === noteId);
    setOriginalNotes({ ...originalNotes, [noteId]: { ...noteToEdit } });
    setEditMode({ ...editMode, [noteId]: true });
    setCurrentEditingNote(noteId);
    setEditSelectedFiles([]);
  };

  const handleCancelEdit = (noteId) => {
    const originalNote = originalNotes[noteId];
    setNotes(
      notes.map((note) => (note._id === noteId ? { ...originalNote } : note))
    );
    setEditMode({ ...editMode, [noteId]: false });
    setErrorMessages({ ...errorMessages, [noteId]: '' });
    setEditSelectedFiles([]);
    setCurrentEditingNote(null);
    fetchNotes();
  };

  const handleSaveEdit = async (noteId) => {
    const updatedNote = notes.find((note) => note._id === noteId);
    if (
      !updatedNote.note.trim() &&
      updatedNote.attachments.length === 0 &&
      editSelectedFiles.length === 0
    ) {
      setErrorMessages({
        ...errorMessages,
        [noteId]: 'Note cannot be empty',
      });
      return;
    }
    setEditingNote(true);
    try {
      const formData = new FormData();
      formData.append('note', updatedNote.note);

      // Append existing attachments
      updatedNote.attachments.forEach((attachment, index) => {
        formData.append(`existingAttachments[${index}]`, attachment);
      });

      // Append new attachments
      editSelectedFiles.forEach((file) => {
        formData.append('files', file);
      });

      await editNote(id, noteId, formData);
      setEditMode({ ...editMode, [noteId]: false });
      setEditSelectedFiles([]);
      setCurrentEditingNote(null);
      fetchNotes(); // Refresh notes after successful edit
    } catch (error) {
      console.error('Error updating note:', error);
    } finally {
      setEditingNote(false);
    }
  };

  const handleRemoveAttachment = (noteId, attachmentIndex) => {
    setNotes(
      notes.map((note) =>
        note._id === noteId
          ? {
              ...note,
              attachments: [...note.attachments].filter(
                (_, index) => index !== attachmentIndex
              ),
            }
          : note
      )
    );
  };

  const handleRemoveEditSelectedFile = (index) => {
    setEditSelectedFiles(editSelectedFiles.filter((_, i) => i !== index));
  };

  const getFormattedDate = (createdAt) => {
    const date = new Date(createdAt);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString('default', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    return `${month} ${day} ${year} at ${time}`;
  };

  const handleConfirmEditModal = () => {
    setEditModalShow(false);
    navigate('/admin/job-management');
  };

  const handleSelectAll = () => {
    if (selectedCrew.size === crewMember.length) {
      // If all are currently selected, deselect all
      setSelectedCrew(new Set());
    } else {
      // Otherwise, select all
      const newSelectedCrew = new Set(crewMember.map((member) => member._id));
      setSelectedCrew(newSelectedCrew);
    }
  };

  useEffect(() => {
    if (initialJobData) {
      setJobName(initialJobData.name);
      setSelectedTeamLeaders(initialJobData.teamLeadIds);
      setStatus(initialJobData.status);
      const crewMemberIds = initialJobData.crewMembers.map(
        (member) => member.crewId
      );
      setSelectedCrew(new Set(crewMemberIds));
      setNotes(initialJobData.notes || []);
    }
  }, [initialJobData]);

  useEffect(() => {
    const hasChanges = () => {
      if (!initialJobData) return false;
      const initialCrewIds = new Set(
        initialJobData.crewMembers.map((member) => member.crewId)
      );
      const currentCrewIds = new Set(selectedCrew);

      // Check if any of the required fields are empty or unchanged
      const isJobNameEmpty = jobName.trim() === '';
      const isCrewEmpty = selectedCrew.size === 0;
      const areTeamLeadersEmpty = selectedTeamLeaders.length === 0;

      const jobNameChanged = jobName.trim() !== initialJobData.name;
      const teamLeadersChanged =
        selectedTeamLeaders !== initialJobData.teamLeadIds;
      const statusChanged = status !== initialJobData.status;
      const crewChanged =
        Array.from(currentCrewIds).sort().toString() !==
        Array.from(initialCrewIds).sort().toString();

      return (
        !isJobNameEmpty &&
        !isCrewEmpty &&
        !areTeamLeadersEmpty &&
        (jobNameChanged || teamLeadersChanged || statusChanged || crewChanged)
      );
    };

    setIsDirty(hasChanges());
  }, [jobName, selectedTeamLeaders, status, selectedCrew, initialJobData]);

  const sortedCrewMembers = [...crewMember].sort((a, b) => {
    const aSelected = selectedCrew.has(a._id);
    const bSelected = selectedCrew.has(b._id);
    if (aSelected === bSelected) {
      return 0;
    }
    return aSelected ? -1 : 1;
  });

  return (
    <>
      {loading ? (
        <div className="jobLoader colorLoader">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-xl-7 border-right pe-xl-4">
            <div className="row gy-3">
              <div className="col-xl-6">
                <div className="form-group check_box ">
                  <label className="fw-bold mb-1" htmlFor="jobName">
                    Enter Job Name
                  </label>
                  <input
                    type="text"
                    className="form-control py-2 w-100"
                    id="jobName"
                    name="jobName"
                    placeholder="Enter a job name"
                    value={jobName}
                    onChange={(e) => setJobName(e.target.value)}
                    required
                    maxLength={30}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <h6 className="fw-bold">Select Team Leaders</h6>
                <div className="multi-select-dropdown" ref={dropdownRef}>
                  <div
                    className="dropdown-header"
                    onClick={() => setShowOptions(!showOptions)}
                  >
                    <div className="dropdown-content">
                      {selectedTeamLeaders.length === 0
                        ? 'Select options'
                        : selectedTeamLeaders
                            .map((selectedLeader) => {
                              const teamLead = teamLeaders.find(
                                (leader) =>
                                  leader._id === selectedLeader.teamLeadId
                              );
                              return teamLead
                                ? `${teamLead.firstName} ${teamLead.lastName}`
                                : selectedLeader.teamLeadId;
                            })
                            .join(', ')}
                    </div>
                    <span className="dropdown-arrow">
                      {showOptions ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </span>
                  </div>
                  <p className={`font-size-13 p-0 m-0 text-danger`}>
                    You can select upto 4 leaders
                  </p>
                  {showOptions && (
                    <div className="dropdown-options" id="scrollstyle">
                      {teamLeaders.map((leader) => (
                        <div
                          key={leader._id}
                          className={`d-flex align-items-center justify-content-between dropdown-option ${
                            selectedTeamLeaders.some(
                              (selectedLeader) =>
                                selectedLeader.teamLeadId === leader._id
                            )
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => handleOptionClick(leader)}
                        >
                          {leader.firstName} {leader.lastName}
                          {selectedTeamLeaders.some(
                            (selectedLeader) =>
                              selectedLeader.teamLeadId === leader._id
                          ) && <FontAwesomeIcon icon={faCheck} className="" />}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="edit_job mt-3">
              <h6 className="fw-bold">Select Status</h6>
              <div className="d-flex">
                <div className="form-group fw-bold d-flex align-items-center pe-4">
                  <input
                    type="radio"
                    id="active"
                    name="status"
                    className="w-auto me-2"
                    value="active"
                    checked={status === 'active'}
                    onChange={() => setStatus('active')}
                  />
                  <label className="fw-normal" htmlFor="active">
                    Active
                  </label>
                </div>
                <div>
                  <div className="form-group fw-bold d-flex align-items-center pe-4">
                    <input
                      type="radio"
                      id="complete"
                      name="status"
                      className="w-auto me-2"
                      value="complete"
                      checked={status === 'complete'}
                      onChange={() => setStatus('complete')}
                    />
                    <label className="fw-normal" htmlFor="complete">
                      Complete
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-1 bg-white p-xl-4 p-md-3 p-4 mt-3 rounded ">
              <div className="row flex-column flex-md-row align-items-md-center justify-content-between">
                <div className="col-xl-5 col-md-4">
                  <BoxTitle
                    className="font-size-16 fw-bold mb-0 ms-4 ps-2"
                    title="All Crew"
                  />
                </div>
                <div className="col-xl-6 col-md-8">
                  <div className="d-flex flex-row gap-4 justify-content-md-end search_icon">
                    <SearchBar
                      className="py-2 h-100 font-size-15"
                      placeholder="Search by crew name"
                      onSearch={handleSearch}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                    />
                  </div>
                </div>
              </div>
              <ul className="pe-3 bg_crew_list mt-4 mt-md-4 mb-2 py-3 rounded">
                <li className="row col-12 crew_icon">
                  <div className="col-6">
                    <ListHeader title="Crew Name" noSorting />
                  </div>
                  <div className="col-6 text-end">
                    <h2
                      className="font-size-16 fw-bold mb-0 text-decoration-underline"
                      onClick={handleSelectAll}
                      style={{ cursor: 'pointer' }}
                    >
                      Select All
                    </h2>
                  </div>
                </li>
              </ul>
              <ul className="pe-3 jobList scroll_bar" id="scrollstyle">
                {sortedCrewMembers.map((crewMember) => (
                  <div key={crewMember.id}>
                    <li className="row col-12">
                      <div className="col-6">
                        <h2 className="font-size-16 crew_name_color mt-2">
                          {crewMember.firstName} {crewMember.lastName}
                        </h2>
                      </div>
                      <div className="col-6 text-end d-flex justify-content-end align-items-center flex-row check_box">
                        <input
                          type="checkbox"
                          id={`crew-${crewMember._id}`}
                          value={crewMember._id}
                          checked={selectedCrew.has(crewMember._id)}
                          onChange={(event) => {
                            const crewId = event.target.value;
                            setSelectedCrew((prevSelectedCrew) => {
                              const newSelectedCrew = new Set(prevSelectedCrew);
                              if (newSelectedCrew.has(crewId)) {
                                newSelectedCrew.delete(crewId);
                              } else {
                                newSelectedCrew.add(crewId);
                              }
                              return newSelectedCrew;
                            });
                          }}
                        />
                      </div>
                    </li>
                    <hr className="hr_line my-2 width-rev-108 width-rev-md-108 width-rev-xl-109" />
                  </div>
                ))}
              </ul>
            </div>
            <div className="d-flex justify-content-end mt-xl-3 mt-md-4 mt-4">
              <Button
                className="py-2 px-4 border_primary border-1 rounded bg-white me-3"
                onClick={() => navigate('/admin/job-management')}
              >
                Cancel
              </Button>
              <Button
                className={`py-2 px-4 border-0 rounded bg_primary width-rev-px-97 loader_btn text-white ${
                  isDirty ? 'enabled-button' : 'disabled-button'
                }`}
                onClick={handleSave}
                disabled={!isDirty || loading}
              >
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {' '}
                    <span className="pe-2"></span>
                    Save
                    <span className="ps-2"></span>
                  </>
                )}{' '}
              </Button>
            </div>
          </div>
          <div className="col-xl-5 ps-xl-4 d-flex flex-column position-relative">
            <div className="d-flex align-items-center">
              <img src={JobNote} alt="Job Notes" />
              <h2 className="font-size-16 mb-0 ms-2 fw-bold">Job Notes</h2>
            </div>
            <ul
              className={`px-3 mt-4 notesList scroll_bar ${
                selectedFiles.length > 0 ? 'with-preview' : ''
              }`}
              id="scrollstyle"
            >
              {notes.length === 0 ? (
                <p className="notes_p text-dark">
                  Type in the below input box to post &quot;Job Notes&quot;
                </p>
              ) : (
                notes.map((note) => (
                  <div key={note._id}>
                    <li>
                      {editMode[note._id] ? (
                        <div className="edit-note">
                          <div className="edit-message-container">
                            <div className="textarea-wrapper text_area border border-1">
                              <div className="m-1">
                                {note.attachments && (
                                  // update this for adding new files
                                  <>
                                    <NoteAttachments
                                      attachments={[
                                        ...note.attachments,
                                        ...editSelectedFiles,
                                      ]}
                                      attachmentsLength={
                                        note.attachments.length
                                      }
                                      editMode
                                      onRemove={(index) =>
                                        index < note.attachments.length
                                          ? handleRemoveAttachment(
                                              note._id,
                                              index
                                            )
                                          : handleRemoveEditSelectedFile(
                                              index - note.attachments.length
                                            )
                                      }
                                    />
                                    {/* this is modal for edit note */}
                                    <FileUploadModal
                                      show={showEditAttachFileModal}
                                      handleClose={
                                        handleCloseEditAttachFileModal
                                      }
                                      onFileSelect={(files) =>
                                        handleEditFileSelect(
                                          currentEditingNote,
                                          files
                                        )
                                      }
                                      editMode
                                      attachmentsLength={
                                        note.attachments.length
                                      }
                                    />
                                  </>
                                )}
                              </div>
                              <textarea
                                ref={(el) =>
                                  (textareaRefs.current[note._id] = el)
                                }
                                className="form-control border-0"
                                value={note.note}
                                onChange={(e) =>
                                  setNotes(
                                    notes.map((n) =>
                                      n._id === note._id
                                        ? { ...n, note: e.target.value }
                                        : n
                                    )
                                  )
                                }
                                onInput={(e) => adjustTextareaHeight(e.target)}
                              />
                              {errorMessages[note._id] && (
                                <p className="text-danger position-absolute font-size-14 bottom-0 left-0 ps-2">
                                  {errorMessages[note._id]}
                                </p>
                              )}
                              <div className="button-container d-flex">
                                <button
                                  className="rounded-2 d-flex align-items-center justify-content-center p-2 file_upload_icon me-2 cursor"
                                  onClick={() =>
                                    handleOpenEditAttachFileModal(note._id)
                                  }
                                  disabled={editingNote}
                                >
                                  <FontAwesomeIcon
                                    icon={faPaperclip}
                                    size="lg"
                                  />
                                </button>
                                <button
                                  className="btn btn-secondary me-2 px-3 w-auto"
                                  onClick={() => handleCancelEdit(note._id)}
                                  disabled={editingNote}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn editNoteSave px-4"
                                  onClick={() => handleSaveEdit(note._id)}
                                  disabled={editingNote}
                                >
                                  {editingNote ? (
                                    <div className="login_loader">
                                      <LoadingSpinner />
                                    </div>
                                  ) : (
                                    <span>Save</span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {note.attachments && (
                            <NoteAttachments attachments={note.attachments} />
                          )}
                          <p className="font-size-14 mt-2 mb-1">{note.note}</p>
                          <div className="font-size-12 d-flex align-items-center">
                            <p className="mb-0 date-color">
                              {getFormattedDate(note.createdAt)}, Posted by{' '}
                              {note.createdBy}
                            </p>
                            <div className="ms-auto d-flex gap-2 align-items-center">
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="edit_icon_color cursor_pointer font-size-18"
                                onClick={() => handleEditNote(note._id)}
                              />
                              <img
                                src={DeleteNote}
                                className="cursor_pointer m-0"
                                alt="Delete Job Note"
                                onClick={() =>
                                  openDeleteConfirmationModal(note._id)
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </li>
                    <hr className="hr-line my-3 width-rev-108 width-rev-md-108 width-rev-xl-106" />
                  </div>
                ))
              )}
            </ul>
            {selectedFiles.length > 0 && (
              <div className="file-preview-wrapper">
                <FilePreview
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              </div>
            )}
            <div className="post_input w-100 mt-auto position-relative">
              <input
                type="text"
                className="form-control py-3"
                id="jobNote"
                name="jobNote"
                placeholder="Add job note..."
                value={jobNote}
                onChange={(e) => setJobNote(e.target.value)}
                required
                disabled={postingNote}
              />
              <div className="d-flex gap-2 align-items-center notes_btn position-absolute">
                <Button
                  onClick={handleOpenAttachFileModal}
                  disabled={postingNote}
                  className={'btn-reset'}
                  title={'Add file'}
                >
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    size="lg"
                    className="file_upload_icon border-0"
                  />
                </Button>
                <Button
                  className={`py-2 px-4 border-0 rounded bg_primary text-white postNoteBtn ${
                    (!jobNote && selectedFiles.length <= 0) || postingNote
                      ? 'opacity-50'
                      : ''
                  }`}
                  onClick={handlePostJobNote}
                  disabled={
                    (!jobNote && selectedFiles.length <= 0) || postingNote
                  }
                >
                  {postingNote ? (
                    <div className="login_loader">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <span>Post</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
          <ModalValidate
            show={editModalShow}
            handleClose={handleConfirmEditModal}
            title="Job Updated successfully"
            confirmTitle="Add Job notes"
            closeTitle="Back to List"
            onConfirm={() => setEditModalShow(false)}
            onClose={() => setEditModalShow(false)}
          />
          <ModalValidate
            show={deleteConfirmationModalOpen}
            handleClose={closeDeleteConfirmationModal}
            title=""
            closeTitle="Cancel"
            confirmTitle="Yes"
            message="Are you sure you want to delete
this job note?"
            onConfirm={() => handleDeleteNote(noteToDeleteId)}
            onClose={closeDeleteConfirmationModal}
            secondaryBtn
            className
          />
          <FileUploadModal
            show={showAttachFileModal}
            handleClose={handleCloseAttachFileModal}
            onFileSelect={handleFileSelect}
          />
        </div>
      )}
    </>
  );
};

export default EditJob;
