import React, { useState, useEffect } from 'react';
import JobList from './JobList';
import BoxTitle from './BoxTitle';
import Button from './Button';
import SearchBar from './Search';
import { fetchJobs } from './api';
import LoadingSpinner from './LoadingSpinner';
import '../styles/Job.scss';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function JobManagement() {
  const [crewData, setCrewData] = useState([]);
  const [filteredCrewData, setFilteredCrewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchJobs();
      const activeCrewData = data.filter((crewMember) => !crewMember.isDeleted);
      setCrewData(activeCrewData);
      setFilteredCrewData(activeCrewData);
    } catch (error) {
      console.error('Error fetching crew data:', error);
      setError('Failed to fetch crew data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (searchInput) => {
    const filtered = crewData.filter((crewMember) =>
      crewMember.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setIsSearching(true);
    setFilteredCrewData(filtered);
  };

  const handleDeleteCrewMember = (id) => {
    const updatedCrewData = crewData.filter(
      (crewMember) => crewMember.id !== id
    );
    setCrewData(updatedCrewData);
    setFilteredCrewData(updatedCrewData);
    fetchData();
    setSearchInput('');
  };

  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
        <BoxTitle
          className="font-size-18 fw-bold mb-0 ms-4 ps-2"
          title="All Jobs"
        />
        <div className="d-flex gap-4 search">
          <SearchBar
            className="py-2 h-100"
            placeholder="Search by job name"
            onSearch={handleSearch}
            onBlur={() => setIsSearching(false)}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <Link to={'/admin/job-management/add-job'} className="link addBtn">
            <Button className="border-0 rounded bg_primary text-white d-flex align-items-center px-3 text-white fw-bold addJobBtn">
              <FontAwesomeIcon icon={faPlus} className="pe-1" />
              Add Job
            </Button>
          </Link>
        </div>
      </div>
      <div className="loader colorLoader">{loading && <LoadingSpinner />}</div>

      {error && <div className="alert alert-danger">{error}</div>}
      {!loading && !error && (
        <JobList
          crewData={filteredCrewData}
          onDeleteCrewMember={handleDeleteCrewMember}
          isSearching={isSearching}
        />
      )}
    </div>
  );
}

export default JobManagement;
