import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from './constants';

const useShiftedDays = () => {
  const [startDay, setStartDay] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get(`${baseURL}/getEnv`);
        setStartDay(response.data.day);
      } catch (error) {
        console.error('Failed to fetch config:', error);
      }
    };

    fetchConfig();
  }, []);

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const shiftValue = startDay ? parseInt(startDay.day, 10) : 0;
  const validShiftValue =
    shiftValue >= 0 && shiftValue < days.length ? shiftValue : 0;
  const shiftedDays = [
    ...days.slice(validShiftValue),
    ...days.slice(0, validShiftValue),
  ];

  return { startDay, shiftedDays };
};

export default useShiftedDays;
