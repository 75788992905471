import axios from 'axios';

// Define your API base URL
// eslint-disable-next-line no-undef
const baseURL =
  process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3001/api';

const platform = 'web';

// Create a new Axios instance
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'x-platform': platform,
  },
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Return a successful response
    return response;
  },
  (error) => {
    // Check if the error is due to unauthorized access (e.g., 401 Unauthorized)
    if (error.response && error.response.status === 401) {
      // Redirect to the login page
      window.location.href = '/admin/login'; // Replace with your login page URL
    }
    // Return any other errors
    return Promise.reject(error);
  }
);

export const userLogin = async (loginFields) => {
  const response = await axiosInstance.post('/users/login', loginFields);
  return response.data;
};

export const userLogout = async () => {
  const response = await axiosInstance.get('/users/logout');
  return response.data;
};

export const getUserInfo = async () => {
  const response = await axiosInstance.get('/users/user-info');
  return response.data;
};

// Function to fetch crew members
export const fetchCrewMembers = async () => {
  const response = await axiosInstance.get('/users');
  return response.data;
};

export const fetchUserByRoles = async () => {
  const response = await axiosInstance.get('/users/by-roles');
  return response.data;
};

export const checkEmailExists = async (email) => {
  const response = await axiosInstance.get(`/users/check-email?email=${email}`);
  return response.data.exists;
};

// Function to add a new crew member
export const addCrewMember = async (newCrewMember) => {
  const response = await axiosInstance.post('/users/register', newCrewMember);
  return response.data;
};

export const editCrewMember = async (crewMemberId, updatedCrewData) => {
  const response = await axiosInstance.put(
    `/users/${crewMemberId}`,
    updatedCrewData
  );
  return response.data;
};

export const deleteCrewMember = async (crewMemberId) => {
  const response = await axiosInstance.delete(`/users/${crewMemberId}`);
  return response.data;
};

export const fetchJobs = async () => {
  const response = await axiosInstance.get('/job');
  return response.data;
};

export const addJob = async (jobData) => {
  const response = await axiosInstance.post('/job', jobData);
  return response.data;
};

export const editJob = async (jobId, updatedJobData) => {
  const response = await axiosInstance.put(`/job/${jobId}`, updatedJobData);
  return response.data;
};

export const getSingleJob = async (jobId) => {
  const response = await axiosInstance.get(`/job/${jobId}`);
  return response.data;
};

export const deleteJob = async (jobId) => {
  const response = await axiosInstance.delete(`/job/${jobId}`);
  return response.data;
};

export const getNotes = async (jobId) => {
  const response = await axiosInstance.get(`/job/${jobId}/notes`);
  return response.data;
};

export const postNote = async (jobId, jobNote, files) => {
  const formData = new FormData();
  if (jobNote.trim()) {
    formData.append('note', jobNote);
  } else {
    formData.append('note', jobNote);
  }

  files.forEach((file) => {
    formData.append('files', file); // 'files' is the key the backend expects
  });

  const response = await axiosInstance.post(`/job/${jobId}/notes`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const editNote = async (jobId, noteId, formData) => {
  try {
    const response = await axiosInstance.put(
      `/job/${jobId}/notes/${noteId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in editNote:', error);
    throw error;
  }
};

export const deleteNote = async (jobId, noteId) => {
  const response = await axiosInstance.delete(`/job/${jobId}/notes/${noteId}`);
  return response.data;
};

export const getEnv = async () => {
  const response = await axiosInstance.get('/getEnv');
  return response.data;
};
