import sort from '../assets/images/sort.svg';
import PropTypes from 'prop-types';
import '../styles/shiftReport.scss';
import useShiftedDays from '../utils/useShiftedDays';

function ShiftReportHeader({ title, onSortClick, isEmpty }) {
  const { shiftedDays } = useShiftedDays();

  return (
    <>
      <table className="table table-header mb-1">
        <thead className="thead">
          <tr className="rounded py-2">
            <th className="thead_bg">
              <div className="d-flex align-items-center">
                <span className="font-size-16 fw-bold mb-0 me-2">{title}</span>
                <img
                  src={sort}
                  className="cursor_pointer"
                  onClick={onSortClick}
                />
              </div>
            </th>
            {shiftedDays.map((day, index) => (
              <th key={index} className="thead_bg text-center">
                <span className="crew_time_color">{day}</span>
              </th>
            ))}
            <th className="thead_bg text-center">
              <span className="font-size-16 fw-bold mb-0">Total Hours</span>
            </th>
            <th className="thead_bg text-center">
              <span className="font-size-16 fw-bold mb-0 me-2">Over Time</span>
            </th>
          </tr>
        </thead>
      </table>
      {isEmpty && (
        <p className="mt-5 font-size-18 opacity-75 fw-semibold d-flex justify-content-center">
          No data available
        </p>
      )}
    </>
  );
}
ShiftReportHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool,
};

export default ShiftReportHeader;
