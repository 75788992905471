import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  getFileExtension,
  getFileIcon,
  getMimeTypeFromExtension,
  handleFileDownload,
  isImageFile,
  truncateFileName,
} from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/fileUpload.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ImagePreviewModal from './ImagePreviewModal';
import download from '../assets/fileIconPreviews/download.svg';

const FileDisplay = ({ attachment, editMode, onRemove }) => {
  const isFileObject = attachment instanceof File;
  const filename = isFileObject
    ? attachment.name
    : attachment.split('/').pop().split('__').slice(1).join('__');
  const extension = getFileExtension(filename);
  const isImage = isImageFile(extension);
  const mimeType = isFileObject
    ? attachment.type
    : getMimeTypeFromExtension(extension);

  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);

  const handleImagePreviewModal = () => {
    if (isImage) {
      setShowImagePreviewModal(true);
    }
  };

  const handleCloseImagePreviewModal = () => setShowImagePreviewModal(false);

  return (
    <>
      <div className="d-flex align-items-center gap-2 bg-white h-100 m-0 p-0 px-2 py-1 border rounded-2 fileListItem justify-content-between">
        <div
          className={`d-flex gap-2 align-items-center ${
            isImage ? 'cursor' : ''
          }`}
          onClick={handleImagePreviewModal}
        >
          {isImage ? (
            <div className="rounded-2 d-flex">
              <img
                src={
                  isFileObject ? URL.createObjectURL(attachment) : attachment
                }
                alt={filename}
                className="filePreview rounded-2"
              />
            </div>
          ) : (
            <div className="d-flex">
              <img src={getFileIcon(mimeType)} className="filePreview" />
            </div>
          )}
          <p className="m-0 font-size-14">{truncateFileName(filename)}</p>
        </div>
        {editMode ? (
          <button onClick={onRemove} className="btn-reset" title="remove file">
            <FontAwesomeIcon icon={faTimes} className="removeFileIcon" />
          </button>
        ) : (
          <button
            onClick={() => handleFileDownload(attachment)}
            className="btn-reset cursor"
            title="download file"
          >
            <img src={download} className="filePreview__download" />
          </button>
        )}
      </div>
      <ImagePreviewModal
        show={showImagePreviewModal}
        handleClose={handleCloseImagePreviewModal}
        url={attachment}
      />
    </>
  );
};

const NoteAttachments = ({ attachments, editMode, onRemove }) => {
  return (
    <div className="note-attachments">
      {attachments.map((attachment, index) => (
        <FileDisplay
          key={index}
          attachment={attachment}
          editMode={editMode}
          onRemove={() => onRemove(index)}
        />
      ))}
    </div>
  );
};

FileDisplay.propTypes = {
  attachment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
  ]),
  editMode: PropTypes.bool,
  onRemove: PropTypes.func,
};

NoteAttachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)])
  ),
  editMode: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default NoteAttachments;
