import React from 'react';
import PropTypes from 'prop-types';

function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <ul className="pagination pagination_list d-flex justify-content-end flex-row mb-0">
      <li className="page-item">
        <button
          onClick={() => onPageChange(Math.max(1, currentPage - 1))}
          className="page-link border-0"
        >
          {'<'}
        </button>
      </li>
      {Array.from({ length: totalPages }).map((_, index) => (
        <li
          key={index}
          className={`page-item${currentPage === index + 1 ? ' active' : ''}`}
        >
          <button
            onClick={() => onPageChange(index + 1)}
            className="page-link border-0"
          >
            {index + 1}
          </button>
        </li>
      ))}
      <li className="page-item">
        <button
          onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
          className="page-link border-0"
        >
          {'>'}
        </button>
      </li>
    </ul>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
