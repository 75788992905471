import HeaderLogo from '../assets/images/header-logo.svg';
import User from '../assets/images/user.svg';
export default function NavBar() {
  return (
    <nav>
      <div className="container-fluid  d-flex justify-content-center py-4">
        <div className="row align-items-center">
          <div className="col-3">
            <img
              src={HeaderLogo}
              className="width-rev-px-180 width-rev-px-xl-200 width-rev-px-md-140 height-rev-px-30"
            />
          </div>
          <div className="col-9 d-none">
            <div className="d-flex align-items-center justify-content-end">
              <div className="pe-2">
                <img
                  src={User}
                  className="width-rev-px-44 height-rev-px-44 rounded-pill"
                />
              </div>
              <div>
                <h1 className="font-size-14 mb-0 fw-bold">Starc Will</h1>
                <h2 className="font-size-12 mb-0 mt-2">Admin</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
