import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import sort from '../assets/images/sort.svg';
import Pagination from './Pagination';
import '../styles/shiftReport.scss';

function BreakdownByJob({ reportData }) {
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change as needed
  const listRef = useRef(null);

  if (!reportData || !reportData.jobs || !Array.isArray(reportData.jobs)) {
    return <p>No job data available.</p>;
  }

  // Extract unique crew names from the reportData
  const uniqueCrewNames = reportData.jobs.reduce((names, job) => {
    job.users.forEach((user) => {
      if (!names.includes(user.name)) {
        names.push(user.name);
      }
    });
    return names;
  }, []);

  // Sort unique crew names based on sortOrder
  const sortedCrewNames = [...uniqueCrewNames].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.localeCompare(b);
    } else {
      return b.localeCompare(a);
    }
  });

  // Function to convert "hh:mm" to total minutes
  const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  // Function to convert total minutes to "hh:mm"
  const convertToHHMM = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}`;
  };

  // Map jobId to total hours in summary
  const totalHoursPerJobSummary = reportData.jobSummary.reduce(
    (totalHours, job) => {
      totalHours[job.jobId] = job.totalHours;
      return totalHours;
    },
    {}
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedCrewNames.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total hours for the current page
  const totalHoursForCurrentPage = reportData.jobs.reduce((totals, job) => {
    const total = currentItems.reduce((sum, crewName) => {
      const userTotal = job.users
        .filter((user) => user.name === crewName)
        .reduce((userSum, user) => {
          return userSum + convertToMinutes(user.totalHoursForThisJob);
        }, 0);
      return sum + userTotal;
    }, 0);
    totals[job.jobId] = total;
    return totals;
  }, {});

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };

  const handleSortClick = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <>
      <div className="scrollable-tbody" id="scrollstyle" ref={listRef}>
        <table>
          <thead>
            <tr>
              <th className="py-3">
                <div className="d-flex align-items-center">
                  <h2 className="font-size-16 mb-0 fw-bold">Crew Name</h2>
                  <img
                    src={sort}
                    className="cursor_pointer ps-2"
                    onClick={handleSortClick}
                  />
                </div>
              </th>
              {reportData.jobs.map((job) => (
                <th key={job.jobId} className="text-center job-column">
                  {job.jobName}
                </th>
              ))}
            </tr>
          </thead>
          {reportData.jobs.length > 0 ? (
            <tbody>
              {currentItems.map((crewName, crewIndex) => (
                <React.Fragment key={crewIndex}>
                  <tr>
                    <td className="timerBorder crew_name_color">{crewName}</td>
                    {reportData.jobs.map((job, jobIndex) => {
                      const userTotalMinutes = job.users
                        .filter((user) => user.name === crewName)
                        .reduce((sum, user) => {
                          return (
                            sum + convertToMinutes(user.totalHoursForThisJob)
                          );
                        }, 0);
                      return (
                        <td
                          key={`${jobIndex}-${crewIndex}`}
                          className="timerBorder text-center crew_time_color"
                        >
                          {convertToHHMM(userTotalMinutes)}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              ))}
              <tr className="total_hour">
                <td>Total Hours</td>
                {reportData.jobs.map((job) => (
                  <td key={job.jobId} className="text-center">
                    {convertToHHMM(totalHoursForCurrentPage[job.jobId])}
                  </td>
                ))}
              </tr>
              <tr className="total_job">
                <td className="py-2 bg-white">Total Job Hours</td>
                {reportData.jobs.map((job) => (
                  <td key={job.jobId} className="text-center">
                    {totalHoursPerJobSummary[job.jobId]}
                  </td>
                ))}
              </tr>
            </tbody>
          ) : (
            <p className="d-flex align-items-center justify-content-center mt-5 font-size-18 opacity-75 fw-semibold">
              No data available
            </p>
          )}
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(sortedCrewNames.length / itemsPerPage)}
        onPageChange={paginate}
      />
    </>
  );
}

BreakdownByJob.propTypes = {
  reportData: PropTypes.shape({
    jobs: PropTypes.arrayOf(
      PropTypes.shape({
        jobId: PropTypes.string.isRequired,
        jobName: PropTypes.string.isRequired,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            userId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            totalHoursForThisJob: PropTypes.number.isRequired,
          })
        ).isRequired,
        totalHours: PropTypes.number.isRequired,
      })
    ).isRequired,
    jobSummary: PropTypes.arrayOf(
      PropTypes.shape({
        jobId: PropTypes.string.isRequired,
        jobName: PropTypes.string.isRequired,
        totalHours: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default BreakdownByJob;
