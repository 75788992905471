import React from 'react';
import sort from '../assets/images/sort.svg';
import PropTypes from 'prop-types';

function ListHeader({ title, onSortClick, noSorting }) {
  return (
    <div className="d-flex align-items-center">
      <h2 className="font-size-16 fw-bold mb-0 me-2">{title}</h2>
      {!noSorting && (
        <img
          src={sort}
          alt="sort"
          className="cursor_pointer"
          onClick={onSortClick}
        />
      )}
    </div>
  );
}
ListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
  noSorting: PropTypes.bool,
};

export default ListHeader;
