import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../styles/fileUpload.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import '../styles/fileUpload.scss';
import { getEnv } from './api';

function FileUploadModal({
  show,
  handleClose,
  onFileSelect,
  editMode,
  attachmentsLength,
}) {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileSize, setFileSize] = useState(null);
  const [filesLimit, setFilesLimit] = useState(null);
  const allowedFileTypes = '.xlsx,.xls,.dwg,.jpg,.jpeg,.png,.pdf,.docx,.doc';

  useEffect(() => {
    const fetchFileSize = async () => {
      try {
        const response = await getEnv();
        setFileSize(response.fileSize);
        setFilesLimit(response.filesLimit);
      } catch (error) {
        console.error('Error fetching file size:', error);
      }
    };

    fetchFileSize();
  }, [show]);

  const bytesToMB = (bytes) => {
    const MB = 1048576; // 1 MB = 1048576 bytes
    return Number(bytes) / MB;
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert to array

    const invalidFiles = files.filter((file) => !isValidFileType(file));
    if (invalidFiles.length > 0) {
      alert('Please upload only excel, dwg, jpg, png, pdf, or doc files.');
      event.target.value = null;
      return;
    }

    const oversizedFiles = files.filter((file) => file.size > Number(fileSize)); // 20MB in bytes
    if (oversizedFiles.length > 0) {
      alert(
        `File size should be less than or equal to ${bytesToMB(fileSize)} MB.`
      );
      event.target.value = null; // Reset the file input
      return;
    }

    onFileSelect((prevFiles) => {
      const totalFiles = [...prevFiles, ...files];

      const maxFiles = editMode
        ? totalFiles.length + attachmentsLength
        : totalFiles.length;

      if (maxFiles > Number(filesLimit)) {
        alert(`You can only select up to ${filesLimit} files.`);
        return prevFiles; // Keep the previous files intact
      }

      return totalFiles;
    });

    handleClose(); // Close the modal if files are selected
  };

  const handleFiles = (files) => {
    const filesArray = Array.from(files);

    const invalidFiles = filesArray.filter((file) => !isValidFileType(file));
    if (invalidFiles.length > 0) {
      alert('Please upload only excel, dwg, jpg, png, pdf, or doc files.');
      return;
    }

    const oversizedFiles = filesArray.filter(
      (file) => file.size > Number(fileSize)
    );
    if (oversizedFiles.length > 0) {
      alert(
        `File size should be less than or equal to ${bytesToMB(fileSize)} MB.`
      );
      return;
    }

    onFileSelect((prevFiles) => {
      const totalFiles = [...prevFiles, ...files];

      const maxFiles = editMode
        ? totalFiles.length + attachmentsLength
        : totalFiles.length;

      if (maxFiles > Number(filesLimit)) {
        alert(`You can only select up to ${filesLimit} files.`);
        return prevFiles; // Keep the previous files intact
      }

      return totalFiles;
    });

    handleClose();
  };
  const isValidFileType = (file) => {
    const allowedExtensions = allowedFileTypes.split(',');
    const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="fileUpload_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-column align-items-center mx-auto">
          <p className="title">Attach File</p>
          <p className="subtitle text-muted">
            You can upload up to 5 files max
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="text-center">
            <div
              className={`upload-box rounded p-3 cursor ${
                isDragging ? 'dragging' : ''
              }`}
              onClick={handleBrowseClick}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div className="upload-icon mb-2">
                <FontAwesomeIcon icon={faCloudArrowUp} size="lg" />
              </div>
              <div className="upload-text">
                <span>Drag your file(s) or </span>
                <span className="upload-button">browse</span>
              </div>
              <div className="mt-2">
                <p className="fileSize_limit">Max 20 MB files are allowed</p>
              </div>
            </div>
            <p className="mt-2 fileSupport_type">
              Only support excel, dwg, .jpg, .png and .pdf files
            </p>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
            className="d-none"
            accept={allowedFileTypes}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
}

FileUploadModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  onFileSelect: PropTypes.func,
  editMode: PropTypes.bool,
  attachmentsLength: PropTypes.number,
};

export default FileUploadModal;
