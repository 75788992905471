import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Pagination from './Pagination';
import '../styles/shiftReport.scss';
import sort from '../assets/images/sort.svg';
import useShiftedDays from '../utils/useShiftedDays';

function SummaryReport({ crewData }) {
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change as needed
  const { shiftedDays } = useShiftedDays();
  const listRef = useRef(null);

  const handleSortClick = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const sortedCrewData = [...crewData].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.userName.localeCompare(b.userName);
    } else {
      return b.userName.localeCompare(a.userName);
    }
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedCrewData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling behavior
      });
    }
  };

  return (
    <>
      <div className="table-container" id="scrollstyle">
        <table className="table mb-1">
          <thead className="thead">
            <tr>
              <th className="thead_bg py-3">
                <div className="d-flex align-items-center">
                  <span className="font-size-16 fw-bold mb-0 me-2">
                    Crew Name
                  </span>
                  <img
                    src={sort}
                    className="cursor_pointer"
                    onClick={handleSortClick}
                  />
                </div>
              </th>
              {shiftedDays.map((day, index) => (
                <th key={index} className="text-center thead_bg py-3">
                  <span className="crew_time_color">{day}</span>
                </th>
              ))}
              <th className="text-center thead_bg py-3">
                <span className="font-size-16 fw-bold mb-0">Total Hours</span>
              </th>
              <th className="text-center thead_bg py-3">
                <span className="font-size-16 fw-bold mb-0 me-2">
                  Over Time
                </span>
              </th>
            </tr>
          </thead>
          <tbody ref={listRef}>
            {currentItems.map((crewMember) => (
              <>
                <tr key={crewMember.userId} className="">
                  <td className="timerBorder">
                    <span className="crew_name_color">
                      {crewMember.userName}
                    </span>
                  </td>
                  {crewMember.weeklyHours.map((dayData, index) => (
                    <td key={index} className="timerBorder text-center">
                      <span className="crew_time_color">
                        {Object.values(dayData)[0]}
                      </span>
                    </td>
                  ))}
                  <td className="timerBorder text-center">
                    <span
                      className={` ${
                        parseInt(
                          crewMember.totalWeekHours.replace(':', ''),
                          10
                        ) /
                          100 >
                        40
                          ? 'text-danger danger-style py-1'
                          : 'text-success success-style py-1'
                      }`}
                    >
                      {crewMember.totalWeekHours}
                    </span>
                  </td>
                  <td className="timerBorder text-center">
                    <span className="crew_time_color">
                      {crewMember.overtime}
                    </span>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(sortedCrewData.length / itemsPerPage)}
        onPageChange={paginate}
      />
    </>
  );
}

SummaryReport.propTypes = {
  crewData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      weeklyHours: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number))
        .isRequired,
      totalWeekHours: PropTypes.number.isRequired,
      overtime: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default SummaryReport;
